// @ts-ignore
import { Text, Box } from 'theme-ui'
import React, { forwardRef, Fragment, ReactNode } from 'react'
import { ColorOption, Option, SelectedValue } from '~/components/parameters/color-parameter/types'
import { space } from '~/gatsby-plugin-theme-ui'
import { Paragraph } from '~/styled/components/typography'

type Props = {
  children?: ReactNode
  option: Option
  colorOptions: readonly ColorOption[]
  selectedValue: SelectedValue
  isFabric?: bool
}

type Ref = HTMLDivElement

const TruncateColor = (a: string, b: number) => {
  if (a.length <= b) {
    return a
  }
  return a.slice(0, b) + '...'
}

const ColorTitle = forwardRef<Ref, Props>(
  ({ option, colorOptions, selectedValue, isFabric }, ref) => {
    return (
      <Paragraph className="color-title" ref={ref}>
        <span className="label" style={{ fontWeight: 400 }}>
          {option.label}
        </span>
        <span className="separator">|</span>
        {colorOptions ? (
          <Fragment>
            {colorOptions.map(colorOption => {
              const colorName = colorOption.title.split('|')
              return (
                <Text
                  as="span"
                  key={`color-option-name-${Math.random()}}`}
                  data-title={colorOption.title}
                  sx={{
                    display: colorOption.title === selectedValue ? 'inline-flex' : 'none',
                  }}
                >
                  {/* Truncate the title on mobile if needed: <Text sx={{display: ['block', 'none']}}>{TruncateColor(colorOption.title.replace('|', '•'), 17)}</Text> */}
                  {isFabric ? (
                    <Text sx={{ display: ['block'] }}>{colorName[1]}</Text>
                  ) : (
                    <Text sx={{ display: ['block'] }}>{colorOption.title.replace('|', '•')}</Text>
                  )}
                </Text>
              )
            })}
          </Fragment>
        ) : (
          <Text as="span">{selectedValue}</Text>
        )}
      </Paragraph>
    )
  }
)

export default ColorTitle
