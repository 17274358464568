import styled from 'styled-components'
import { FONTS } from '~/styled/components/typography'
export const StyledProductLinks = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  /* padding-left: 100px; */

  margin-top: 24px;
  margin-bottom: 36px;

  gap: 8px;
  text-transform: uppercase;

  color: #585858;

  font-size: 14px;
  font-family: ${FONTS.Helvetica};

  a {
    color: inherit;
    text-decoration: none;
  }
`
