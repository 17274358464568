/** @jsx jsx */
import { jsx, Image, ImageProps, AspectRatioProps } from 'theme-ui'
import { FC } from 'react'
import { ShopifyImageSource } from '~/@types/models'
import ImageWrapper from '~/components/image-wrapper'

type Props = Pick<AspectRatioProps, 'ratio'> &
  ImageProps & {
    source: ShopifyImageSource
  }

const ResponsiveImage: FC<Props> = ({ source, ratio, alt, ...rest }) => {
  return (
    <ImageWrapper ratio={ratio}>
      <Image
        srcSet={`
            ${source.srcXSmall} 56w,
            ${source.srcSmall} 240w,
            ${source.srcMobile} 768w,
            ${source.srcDesktop} 1024w,
            ${source.srcDesktopMedium} 1600w,
            ${source.srcLargeDesktop} 2048w,
            ${source.originalSrc}`}
        src={source.srcDesktop}
        alt={alt || 'Responsive Image'}
        {...rest}
        sx={{
          ...rest.sx,
        }}
      />
    </ImageWrapper>
  )
}

export default ResponsiveImage
