import React from 'react'
import { BreadCrumbsProps } from './types'
import { Link } from '../linkPrev/LinkPrev'

import './styles.css'
import { SeparatorsNames } from './constants'

const Breadcrumbs = ({ breadcrumbs, separator = '•' }: BreadCrumbsProps) => {
  return (
    <nav className="breadcrumb" aria-label="Breadcrumb">
      <ol className="breadcrumb__list">
        {breadcrumbs.map((breadcrumb, i) => (
          <li className="breadcrumb__item" key={breadcrumb.url}>
            {i < breadcrumbs.length - 1 ? (
              <>
                <Link
                  className="breadcrumb__link previous-page"
                  to={breadcrumb.url}
                  aria-current="page"
                >
                  {breadcrumb.title}
                </Link>
                <span
                  className={`breadcrumb__separator separator-${SeparatorsNames[separator]}`}
                  aria-hidden="true"
                >
                  {separator}
                </span>
              </>
            ) : (
              <span className="breadcrumb__link">{breadcrumb.title}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
