/** @jsx jsx **/
import React, { FC, Fragment, useEffect, useMemo } from 'react'
import { jsx, Box, Button, Grid } from 'theme-ui'
import Img from 'gatsby-image'
import { useFlyOutContext } from '~/containers/fly-out/contex'
import FlyOutGroup from '~/containers/fly-out/fly-out-group'
import FlyOutHeader from '~/containers/fly-out/fly-out-header'
import FlyOutGroupWrapper from '~/containers/fly-out/fly-out-group-wrapper'
import { CollectionType, ProductItemComponentType } from '~/@types/models'
import theme, { space } from '~/gatsby-plugin-theme-ui'
import { ArrowMenu } from '~/components/icons/arrowMenu'
import { graphql, useStaticQuery } from 'gatsby'
import CartOverlayContent from '~/components/cart-overlay/cart-overlay-content'
import CloseButton from '~/components/close-button'
import { RiCloseFill, RiShoppingCart2Fill } from '@remixicon/react'
import useMediaQuery from '~/hooks/use-media-query'
import { useCartContext } from '~/context/cart-context'

type Props = {
  currentProduct: ProductItemComponentType
}

type QueryResult = {
  collections: {
    edges: Array<{
      node: CollectionType
    }>
  }
}

const OpenPop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet"
    style={{ width: '18px', height: '18px' }}
  >
    <g
      transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
      fill="#2b2b2b"
      stroke="none"
    >
      <path d="M140 210 c0 -5 10 -10 22 -10 20 0 17 -5 -22 -45 -57 -58 -43 -72 15 -15 40 39 45 42 45 22 0 -12 5 -22 10 -22 6 0 10 18 10 40 l0 40 -40 0 c-22 0 -40 -4 -40 -10z" />
      <path d="M24 177 c-3 -8 -4 -45 -2 -83 l3 -69 80 0 80 0 3 48 c2 28 -1 47 -7 47 -6 0 -11 -18 -11 -40 l0 -40 -65 0 -65 0 0 65 0 65 40 0 c22 0 40 5 40 10 0 16 -90 12 -96 -3z" />
    </g>
  </svg>
)

const query = graphql`
  query {
    collections: allShopifyCollection(
      filter: {
        handle: {
          in: [
            "luxury-fabric"
            "linen-fabric"
            "performance-fabric"
            "distressed-textured-weave"
            "outdoor-fabric"
            "wool-fabric"
          ]
        }
      }
      sort: { fields: metafields___rugcollectionlinkorder, order: ASC }
    ) {
      edges {
        node {
          ...ProductDetailPageUpholsteryFragment
        }
      }
    }

    image: file(relativePath: { eq: "upholstery-stack-v2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const FlyOut: FC<Props> = ({ currentProduct }) => {
  const { refFlyOut, isFlyOut, openFlyOut, closeFlyOut } = useFlyOutContext()
  const { items } = useCartContext()
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[0]})`,
  })
  const [showCart, setShowCart] = React.useState(false)
  const {
    collections: { edges: collection },
    // @ts-ignore
    image,
  } = useStaticQuery<QueryResult>(query)

  const collections = collection.map(item => item.node)

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box
        // mb={space.md}
        my={space.md}
        sx={{
          fontSize: '16px',
          color: 'zambezi',
        }}
      >
        CUSTOM UPHOLSTERY OPTIONS
      </Box>
      <Button
        variant="noStyle"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          openFlyOut()
        }}
        // onTouchStart={() => openFlyOut()}
        sx={{
          width: '100%',
          position: 'relative',
          display: 'block',
          overflow: 'hidden',
          border: '1px solid',
          borderColor: theme.colors.border,
          color: 'text',
          bg: 'bg',
        }}
      >
        <Img fluid={image?.childImageSharp.fluid} alt="" style={{ pointerEvents: 'none' }} />
        <Box
          as="span"
          sx={{
            minWidth: '30px',
            height: '100%',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            bg: 'bg',
          }}
        >
          <OpenPop />
        </Box>
      </Button>
      <Box
        sx={{
          display: 'flex',
          right: isFlyOut ? 0 : '-100%',
          flexDirection: 'column',
          position: 'fixed',
          zIndex: 'dialog',
          top: 0,
          bg: 'rgba(0,0,0,0.4)',
          width: '100%',
          height: '100%',
          transition: 'all .3s',
        }}
        ref={refFlyOut}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            right: 0,
            width: ['100%', null, null],
            height: '100%',
            bg: 'main',
            overflowY: 'auto',
            transition: 'all .3s',
            opacity: isFlyOut ? 1 : 0,
          }}
        >
          <Box
            as="header"
            sx={{
              top: 0,
              position: 'sticky',
              zIndex: 2,
              bg: theme.colors.main,
              textAlign: 'center',
              p: space.md,
              minHeight: 67,
            }}
          >
            <CloseButton
              close={closeFlyOut}
              sxProps={{
                width: 40,
                height: 40,
              }}
            >
              Close FlyOut
            </CloseButton>
          </Box>
          <Grid
            columns={['1fr', '1fr 1fr', '1fr 600px']}
            sx={{
              maxHeight: 'calc(100% - 67px)',
            }}
          >
            <Box
              sx={{
                maxHeight: '100%',
                overflow: 'auto',
              }}
            >
              <FlyOutHeader collections={collections} currentProduct={currentProduct} />
              {isFlyOut && (
                <FlyOutGroupWrapper>
                  {collections.map(collection => (
                    <FlyOutGroup
                      key={collection.id}
                      collection={collection}
                      currentProduct={currentProduct}
                    />
                  ))}
                </FlyOutGroupWrapper>
              )}
            </Box>

            <Box
              sx={{
                background: '#fff',

                borderLeft: '1px solid #EEEEEE',
                overflow: 'hidden',
                // '.cart-header-title': {
                //   pt: 0,
                //   pb: ['8px', '10px'],
                //   px: ['15px', '20px'],
                //   pl: ['0'],
                //   borderBottom: 'none',
                //   fontSize: ['16px !important', '28px!important'],
                // },
                // '.cart-overlay-body': {
                //   pr: ['15px', '20px'],
                //   pl: [null, '20px'],
                //   pt: [null, '15px'],
                //   pb: [null, '0'],
                // },
                // '.cart-item-details': {
                //   pr: 0,
                //   pl: [0, null, theme.space.md1],
                // },
                // '.cart-flyout > div': {
                //   flexFlow: 'row',
                //   gap: ['10px', null],
                // },
                // '.cart-item-image': {
                //   minWidth: ['unset', 'unset', 'unset'],
                //   width: ['100px', '100px', '180px'],
                //   aspectRatio: '1/1',
                //   img: {
                //     width: '100%',
                //     objectFit: 'cover',
                //   },
                //   '.cart-item-image__wrapper': {
                //     height: '100%',
                //     '& > div': {
                //       height: '100%',
                //     },
                //   },
                // },
                // '.cart-item-quantity-box td > div': {
                //   marginTop: '0',
                // },
                // '.cart-overlay-footer': {
                //   pt: ['0', null, '10px'],
                //   pr: ['20px', '25px'],
                //   pl: [null, '25px'],
                //   pb: ['40px'],
                // },
                display: [showCart ? 'block' : 'none', 'block'],
                ...(isMobile
                  ? {
                      position: 'absolute',
                      bottom: 90,
                      right: 20,
                      zIndex: 'floatButton',
                      height: ' calc(100vh - 190px)',
                      width: 'calc(100vw - 40px)',
                      border: 'none',
                      boxShadow: '0px 0px 45.2px 3px rgba(0,0,0,0.25)',
                    }
                  : {}),
              }}
            >
              <CartOverlayContent />
            </Box>
          </Grid>
        </Box>
        {isMobile && (
          <Button
            as="button"
            sx={{
              display: ['flex', 'none', 'none'],
              position: 'absolute',
              right: 20,
              bottom: 20,
              width: 60,
              height: 60,
              border: 'none',
              fontSize: '24px',
              zIndex: 'floatButton',
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#001f39',
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setShowCart(!showCart)
            }}
          >
            {showCart ? (
              <RiCloseFill />
            ) : (
              <Fragment>
                <Box
                  sx={{
                    background: '#FF2020',
                    color: 'white',
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: 17,
                    height: 17,
                    borderRadius: '50%',
                    fontSize: '14px',
                  }}
                >
                  {items.length}
                </Box>
                <RiShoppingCart2Fill />
              </Fragment>
            )}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default React.memo(FlyOut)
