import styled, { css } from 'styled-components'

export const SwiperCursorZoom = styled.div`
  position: absolute;
  pointer-events: none;
  border: 2px solid #d2d2d2;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: none;
`

export const SwiperCanvasZoom = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 10;
  top: 0;
  left: 0;
  border: 1px solid #d2d2d2;
  display: none;
`

interface WrapperProps {
  $isFullScreen: boolean
  $isRug?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-columns: 121px 1fr;
  gap: 20px;

  width: 100%;
  height: 600px;
  max-height: 600px;

  .thumbs-swiper {
    padding: 40px 5px;
    /* max-height: 410px; */

    .swiper-slide {
      height: auto;
      background: #f8f8f8;
      padding: 2px;
      border: 1px solid transparent;
    }

    .swiper-custom-slide-active {
      border: 1px solid #d2d2d2;
    }
  }

  .main-swiper {
    padding: 0 60px;
    background: #f8f8f8;

    .swiper-slide {
      padding: 2px;

      .swiper-zoom-container {
        ${({ $isRug }) =>
          $isRug &&
          css`
            img {
              object-fit: cover;
            }
          `}
      }
    }

    .swiper-slide-active {
      * {
        cursor: zoom-in;
      }
    }
  }

  ${({ $isFullScreen }) =>
    $isFullScreen &&
    css`
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99999;
      height: 100dvh !important;
      max-height: 100dvh !important;
      height: 100vh !important;
      max-height: 100vh !important;

      ${SwiperCursorZoom} {
        display: none !important;
      }

      grid-template-columns: 200px 1fr;
      padding: 60px 30px;

      .thumbs-swiper {
        max-height: unset !important;
      }

      .main-swiper {
        .swiper-slide-active {
          * {
            cursor: default;
          }
        }
        .swiper-slide-zoomed {
          * {
            cursor: move;
          }
        }
      }
    `}

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 320px;
    max-height: unset;

    .main-swiper {
      flex: 1;
      padding: 0;
    }

    .thumbs-swiper {
      order: 2;
      padding: 0 20px;

      .swiper {
        padding: 0;
      }
    }
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.tablet} {
    height: 380px;
    .main-swiper {
      padding: 0 40px;
    }

    .thumbs-swiper {
      padding: 40px 5px;
      /* max-height: 320px; */
    }

    ${({ $isFullScreen }) =>
      $isFullScreen &&
      css`
        grid-template-columns: 100px 1fr;
      `}
  }
`

export const ZoomButtons = styled.div`
  position: absolute;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
`

const ButtonBase = css`
  border: none;
  cursor: pointer;

  background: transparent;

  color: #000;

  &:disabled {
    color: rgba(16, 16, 16, 0.3);
  }

  width: 50px;
  height: 50px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const ZoomButton = styled.button`
  ${ButtonBase}
`

export const CloseButton = styled.button`
  ${ButtonBase}

  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 90;
`

export const FullScreenButton = styled.button`
  ${ButtonBase}

  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 90;
`
