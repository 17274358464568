/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import { FC } from 'react'
import ResponsiveImage from '~/components/responsive-image'
import { space } from '~/gatsby-plugin-theme-ui'
import Link, { LinkProps } from '~/components/link'
import { Color } from '~/components/product-detail-panel/types'

type SwatchProps = {
  color: Color
  to: string
  active?: boolean
}

type Props = LinkProps & SwatchProps

const ColorSwatch: FC<Props> = ({ color, active, sx, ...rest }) => (
  <Link
    {...rest}
    sx={{
      textDecoration: `none`,
      cursor: `pointer`,

      ...sx,
    }}
  >
    <Box
      sx={{
        position: 'relative',
        width: '60px',
        height: '60px',
        border: `1px solid transparent`,
        padding: `3px`,
        ...(active
          ? {
              borderColor: `#000`,
            }
          : {
              borderColor: `transparent`,
            }),
      }}
    >
      {color.mainImageSwatch && (
        <ResponsiveImage
          source={color.mainImageSwatch}
          alt={`Color variant: ${color.colorName}`}
          ratio={1}
          sizes="100px"
          sx={{
            width: `100%`,
            height: `100%`,
          }}
        />
      )}
    </Box>
    {/* <Text
      as="span"
      sx={{
        display: `block`,
        mt: [space.sm],
        fontSize: '16px',
        color: `#585858`,
      }}
    >
      {color.colorName}
    </Text> */}
  </Link>
)

export default ColorSwatch
