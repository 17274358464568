/** @jsx jsx */
// @ts-ignore
import { jsx, Flex, Box } from 'theme-ui'
import { FC, useCallback, useMemo, useState, Fragment } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'
import renderParameter from '~/components/parameters/render-parameter'
import ColorsList from '~/components/product-detail-panel/colors-list'
import { CheckoutLineItemCustomAttribute, ProductVariantType } from '~/@types/models'
import SelectConfiguration from './select-configuration'
import { useProductDetail } from '~/containers/product-detail-v2/Provider'

type Props = {
  onChange: (option) => void
  hiddenColors?: boolean
  variant: ProductVariantType
  title: string
  customAttributes?: readonly CheckoutLineItemCustomAttribute[]
  initialQuantity?: number
  disabled?: boolean
  label?: string
  showAddToCart?: boolean
}

const reduceOptions = (options, valueIsNull = false) =>
  options.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: valueIsNull ? null : curr.value,
    }
  }, {})

const Parameters: FC<Props> = ({
  onChange,
  hiddenColors = false,
  title,
  customAttributes,
  initialQuantity,
  disabled,
  label,
}) => {
  const {
    product,
    productVariant: variant,
    productsOptions,
    sectionalsOptions,
    currentBundle,
    handleBundleClick,
    sectionalType,
    sectionalCushion,
    handleFrameClick,
  } = useProductDetail()

  let initialValues = reduceOptions(product.options, true)
  if (variant) {
    initialValues = reduceOptions(variant.selectedOptions)
  }

  const [values, setValues] = useState(initialValues)

  const productHandle = product.handle

  const options = useMemo(() => {
    const _options = product.options.map(option => {
      const name = option.name
      let _values

      if (name !== product.options[0].name) {
        const previousOption = values[product.options[0].name]
        const productSizeVariant = product.variants
          .filter(a => a.selectedOptions.find(b => b.value === previousOption))
          .map(a => a.selectedOptions.find(b => b.name === name)?.value)

        _values = new Set(productSizeVariant)
      } else {
        const availableValues = product.variants.map(variant => {
          const selectedOption = variant.selectedOptions.find(option => option.name === name)
          return selectedOption && selectedOption.value
        })

        _values = new Set(availableValues)
      }

      return {
        ...option,
        values: Array.from(_values).filter(value => value !== 'Swatch'),
      }
    })

    return _options
  }, [product, productHandle, values])

  const onChangeParameter = useCallback(
    (option, newValue) => {
      const newValues = {
        ...values,
        [option.name]: newValue,
      }

      onChange(newValues)
      setValues(newValues)
    },
    [values]
  )

  const colorVariants = useMemo(() => {
    if (!product.colorVariants || !product.colorVariants.products) {
      return null
    }

    const variants = product.colorVariants.products
    // .filter(
    //   product => product.handle !== productHandle
    // )

    const hasMoreColorVariants = variants.length > 0

    if (!hasMoreColorVariants) {
      return null
    }

    return variants
  }, [product, productHandle])

  return (
    <Flex
      className="product-parameters"
      sx={{
        flexDirection: 'column',
        gap: [space.md1],
        mb: [space.md1],
      }}
    >
      {options.map(option => {
        if (option.name === sectionalType) return <Fragment></Fragment>

        const parameter = renderParameter(
          product,
          option,
          values[option.name],
          onChangeParameter,
          variant,
          options,
          title,
          customAttributes,
          initialQuantity,
          disabled,
          label
        )

        return (
          <Box key={`option-${option.name}`}>
            <Fragment>
              {parameter}
              {!hiddenColors && option.name === 'Color' && colorVariants && (
                <ColorsList
                  productHandle={product.handle}
                  // @ts-ignore
                  colors={colorVariants}
                  sxProps={{
                    mt: [space.sm1],
                    maxWidth: [`800px`],
                  }}
                />
              )}
            </Fragment>
          </Box>
        )
      })}
      {productsOptions && currentBundle?.label ? (
        <Box>
          <SelectConfiguration
            // @ts-ignore
            onChange={(option, item) => {
              handleBundleClick(item, 'products')
            }}
            stockControl={productsOptions?.stockControl}
            value={currentBundle?.images || currentBundle.label}
            option={productsOptions}
            showAddSwatch={false}
            gridType={currentBundle?.images ? 'image' : 'text'}
          />
        </Box>
      ) : null}

      {sectionalsOptions && currentBundle?.label ? (
        <Box>
          <SelectConfiguration
            // @ts-ignore
            onChange={(option, item) => {
              handleBundleClick(item, 'sectionals')
            }}
            stockControl={sectionalsOptions?.stockControl}
            label={currentBundle?.label}
            value={currentBundle?.images || currentBundle.label}
            option={sectionalsOptions}
            showAddSwatch={false}
            gridType={currentBundle?.images ? 'image' : 'text'}
          />
        </Box>
      ) : null}
      {sectionalCushion && currentBundle?.label ? (
        <Box>
          <SelectConfiguration
            // @ts-ignore
            onChange={(option, item) => {
              handleFrameClick(item === 'Frame Only')
            }}
            label={currentBundle?.frameOnly ? 'Frame Only' : 'Frame and Cushion'}
            value={currentBundle?.frameOnly ? 'Frame Only' : 'Frame and Cushion'}
            option={sectionalCushion.options}
            showAddSwatch={false}
            gridType="text"
          />
        </Box>
      ) : null}
    </Flex>
  )
}

export default Parameters
