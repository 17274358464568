/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable require-unicode-regexp */
import { navigate } from 'gatsby'
import { useLocation } from 'react-use'
import { BreadcrumbItem } from '../breadcrumbs/types'
import { UseBreadcrumbs } from './types'
import { useBreadcrumbState } from '~/context/breadcrumb'
import { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { ProductItemComponentQuery } from '~/@types/models'
import { getProductByHandle } from '~/graphql/getters'

/**
 * useShopifyBreadcrumbs hook
 * Use the hook to get breadcrumbs for shopify pages
 * CMS breadcrumbs are handled on
 * https://admin.shopify.com/store/bensoleimani/content/entries/breadcrumb/7952531520
 * @param product - product object from product page
 * @returns breadcrumbs array
 * @example
 * const { breadcrumbs } = useShopifyBreadcrumbs({ title: 'Title' })
 */
interface Props {
  breadcrumbs: BreadcrumbItem[]
  handleBreadcrumbClick?: (url: string) => void
}

const useShopifyBreadcrumbs = (): Props => {
  const { pathname, origin } = useLocation()
  const { collections, collectionsSub } = useBreadcrumbState()
  const [products, setProducts] = useState<ProductItemComponentQuery | null>(null)

  const breadcrumbs: BreadcrumbItem[] = useMemo(() => {
    const _breadcrumbs: BreadcrumbItem[] = []

    if (isEmpty(collectionsSub)) return _breadcrumbs

    const collectionFromUrl: string | null = pathname?.includes('/collections/')
      ? pathname?.split('/collections/')[1]?.split('/')[0]
      : null

    let collectionFromProduct: string | null = null

    if (products?.collections) {
      products?.collections.every(collection => {
        if (collectionsSub[collection.handle]) {
          collectionFromProduct = collection.handle
          return false
        }

        return true
      })
    }

    if (collectionFromUrl && collections[collectionFromUrl]) {
      _breadcrumbs.push({
        title: 'Home',
        url: '/',
      })

      _breadcrumbs.push({
        title: collections[collectionFromUrl],
        url: `${origin}/collections/${collectionFromUrl}`,
      })

      return _breadcrumbs
    }

    const collectionIndex = (collectionFromUrl || collectionFromProduct) as string

    const collection = {
      ...collectionsSub[collectionIndex],
      productTitle: products?.title,
    }

    if (collection?.title && collection?.handle) {
      const { type, handle, title, productTitle } = collection

      _breadcrumbs.push({
        title: 'Home',
        url: '/',
      })

      _breadcrumbs.push({
        title: collections[type],
        url: `${origin}/collections/${type}`,
      })

      if (type !== 'rugs') {
        _breadcrumbs.push({
          title: title.split(' | ')[0],
          url: `${origin}/collections/${handle}`,
        })
      }

      if (productTitle) {
        _breadcrumbs.push({
          title: productTitle,
          url: pathname as string,
        })
      }
    }

    return _breadcrumbs
  }, [pathname, products, collectionsSub])

  useEffect(() => {
    if (pathname?.includes('/products/')) {
      const productHandle = pathname.split('/products/')[1]
      getProductByHandle(productHandle).then(data => {
        setProducts(data)
      })
    }
  }, [pathname])

  const handleBreadcrumbClick = (url: string) => {
    navigate(url)
  }

  return {
    breadcrumbs,
    handleBreadcrumbClick,
  }
}

export default useShopifyBreadcrumbs
