import styled, { css } from 'styled-components'
import { StyledTag } from '~/componentsV2/tag/styles'
import { FONTS, SmallText } from '~/styled/components/typography'
import { StyledProductLinks } from '../product-links/styles'

const BaseParameter = css`
  color: #2b2b2b;
  font-size: 16px;
  text-transform: uppercase;

  .group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    gap: 8px;
  }

  span {
    color: #585858;
    font-size: 16px;
  }

  .label {
    color: #2b2b2b;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  .color-parameter {
    .color-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;
      gap: 8px;

      ${BaseParameter}
    }
  }

  .colors-list {
    ${BaseParameter}
  }

  .select-parameter {
    ${BaseParameter}
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.desktop} {
    ${StyledProductLinks} {
      display: none;
    }
  }

  /* .product-parameters {
    margin-bottom: 36px;
  } */
`
export const ProductTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .tag {
    text-align: left;
  }
`

export const ProductTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: #2b2b2b;
  margin-bottom: 16px;

  h1,
  h2 {
    margin: 0;
    font-size: 32px;
    color: #2b2828;
    text-transform: uppercase;
  }
`
export const ProductPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h4 {
    font-family: ${FONTS.Helvetica};
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #2b2828;
    margin: 0;
    s {
      color: #585858;
    }
  }

  ${SmallText} {
    font-family: ${FONTS.Inter};
    color: #2b2828;
  }

  ${StyledTag} {
    min-width: 78px;
    font-weight: 400;
  }
`

export const InfoList = styled.ul`
  font-family: ${FONTS.Helvetica};
  font-size: 12px;
  font-weight: 400;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 24px;
  text-transform: uppercase;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }

  li {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  li:first-child {
    margin-top: 12px;
  }

  li:last-child {
    margin-bottom: 12px;
  }

  /* li:before {
    content: '—';
    color: #d9d9d9;
    margin-right: 10px;
  } */
`
