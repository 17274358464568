import React, { FC } from 'react'

type Props = FC<SVGSVGElement>

const Pdf: FC<Props> = ({ ...rest }) => (
  <svg viewBox="0 0 592 432" {...rest}>
    <path
      d="M518.51,296.41h-15.39c-6.73,0-12.19,5.46-12.19,12.19v76.76H300.45v-76.13c0-7.08-5.74-12.82-12.82-12.82h-13.51
				c-7.08,0-12.82,5.74-12.82,12.82v100.99c0,0.92,0.11,1.82,0.29,2.68c0.8,6.27,6.1,11.14,12.59,11.14h243.74
				c7.05,0,12.77-5.72,12.77-12.77v-0.42v-12.73V308.6C530.7,301.87,525.24,296.41,518.51,296.41z"
    />
    <path
      d="M446.23,266.02h-30.86v-83.58c0-6.87-5.57-12.44-12.44-12.44H389.2c-6.87,0-12.44,5.57-12.44,12.44v83.58H345.9
				c-9.02,0-14.2,10.26-8.85,17.52l50.17,68.09c4.4,5.97,13.31,5.97,17.71,0l50.17-68.09C460.44,276.28,455.25,266.02,446.23,266.02
				z"
    />
  </svg>
)

export default Pdf
