/** @jsx jsx */
import { jsx, Link as ThemeUILink, SxStyleProp } from 'theme-ui'
import { FC } from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import useLinkState from '~/hooks/use-link-state'

export interface LinkProps extends GatsbyLinkProps<{}> {
  sx?: SxStyleProp
  variant?: string
  isExternal?: boolean
}

const Link: FC<LinkProps> = ({ children, to, isExternal = false, className, ...rest }) => {
  const { state } = useLinkState()
  if (!isExternal) {
    return (
      // @ts-ignore
      <GatsbyLink className={`gatsby-link-component ${className}`} to={to} state={state} {...rest}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    // @ts-ignore
    <ThemeUILink
      className={`external-link-component ${className}`}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </ThemeUILink>
  )
}

export default Link
