// @ts-ignore
import { SwiperSlide, SwiperClass } from 'swiper/swiper-react'
import { Zoom, Navigation, FreeMode } from 'swiper/modules'

import React, { useEffect, useRef, useState } from 'react'
import {
  CloseButton,
  FullScreenButton,
  SwiperCursorZoom,
  Wrapper,
  ZoomButton,
  ZoomButtons,
} from './styles'
import { CustomSwiper } from '../custom-swiper'
import { Box, Image } from 'theme-ui'
import { useMouseOverZoom } from './hooks/useMouseOverZoom'
import {
  RiCloseCircleLine,
  RiFullscreenExitLine,
  RiFullscreenLine,
  RiPlayLargeFill,
  RiZoomInLine,
  RiZoomOutLine,
} from '@remixicon/react'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import FullPlus from '~/components/icons/fullplus'

export interface ProductViewSliderItem {
  title: string
  type: 'image' | 'video'
  thumb?: string
  image?: string
  videoId?: string
}

export interface ProductDetailViewProps {
  sliders: ProductViewSliderItem[]
  target: React.RefObject<HTMLDivElement>
  onChangeFullScreen?: (isFullScreen: boolean) => void
  isRug?: boolean
}

const MAX_ZOOM_RATIO = 3

const Video = (props: ReactPlayerProps) => (
  <ReactPlayer
    controls={false}
    width="100%"
    autoPlay={true}
    loop={true}
    height="100%"
    playsinline={true}
    playIcon={<RiCloseCircleLine />}
    config={{
      vimeo: {
        playerOptions: {
          width: 1024,
          maxwidth: 1024,
          autoplay: true,
          // responsive: true,
          loop: true,
          playsinline: true,
          muted: true,
        },
      },
    }}
    {...props}
  />
)
const ProductDetailView: React.FC<ProductDetailViewProps> = ({
  sliders,
  target,
  onChangeFullScreen,
  isRug = false,
}) => {
  const cursor = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery({ query: theme.mediaQueries.mobile })

  const { setSource, createTarget } = useMouseOverZoom(target, cursor)

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>()
  const [mainSwiper, setMainSwiper] = useState<SwiperClass>()
  const [currentSlider, setCurrentSlider] = useState(0)

  const [isFullScreen, setFullScreen] = useState(false)
  const [zoomScale, setZoomScale] = useState(1)

  useEffect(() => {
    if (mainSwiper) {
      const source = mainSwiper?.wrapperEl.querySelector('.source-image-0') as HTMLImageElement
      setSource(source)
      if (target.current) {
        createTarget()
      }
    }
  }, [mainSwiper, sliders])

  useEffect(() => {
    if (isFullScreen) {
      onChangeFullScreen?.(true)
      mainSwiper?.zoom.enable()
      document.body.style.overflow = 'hidden'
    } else {
      mainSwiper?.zoom.out()
      mainSwiper?.zoom.disable()
      onChangeFullScreen?.(false)
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [mainSwiper, isFullScreen])

  const changeZoom = (ratio: number) => {
    const newRatio = Math.min(Math.max(zoomScale + ratio, 1), MAX_ZOOM_RATIO)
    mainSwiper?.zoom.in(newRatio)
  }

  return (
    <Wrapper $isFullScreen={isFullScreen} $isRug={isRug}>
      {isFullScreen && (
        <CloseButton onClick={() => setFullScreen(false)}>
          <RiCloseCircleLine />
        </CloseButton>
      )}

      <CustomSwiper
        className="thumbs-swiper"
        arrowSpacing={isMobile ? -30 : -10}
        spaceBetween={10}
        slidesPerView={6}
        slidesPerGroup={1}
        direction={isMobile ? 'horizontal' : 'vertical'}
        breakpoints={{
          [theme.minBreakPoints.mobile]: {
            direction: 'horizontal',
            slidesPerView: 3,
          },
          [theme.minBreakPoints.tablet]: {
            direction: 'vertical',
            slidesPerView: 4,
          },
          [theme.minBreakPoints.lDesktop]: {
            direction: 'vertical',
            slidesPerView: 6,
          },
        }}
        freeMode
        modules={[FreeMode, Navigation]}
        onSwiper={setThumbsSwiper}
        onSlideChangeTransitionStart={swiper => {
          mainSwiper?.slideToLoop(swiper.activeIndex)
        }}
      >
        {sliders.map((o, i) => {
          return (
            <SwiperSlide
              key={o.title}
              onClick={() => {
                mainSwiper?.slideToLoop(i)
              }}
              className={i === currentSlider ? 'swiper-custom-slide-active' : ''}
            >
              {o.type === 'image' ? (
                <Box
                  sx={{
                    aspectRatio: ['105/85'],
                    outline: 'none',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    maxHeight: '75px',
                  }}
                >
                  <Image
                    sx={{
                      aspectRatio: ['105/85'],
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src={o.thumb}
                    loading={i > 5 ? 'lazy' : undefined}
                    alt={o.alt || 'Product Image'}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    aspectRatio: ['105/85'],
                    height: '100%',
                    width: '100%',
                    outline: 'none',
                    pointerEvents: 'none',
                    iframe: {
                      width: '120% !important',
                      height: '100% !important',
                      transform: 'translate(-10%, 0px)',
                    },
                    svg: {
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                      cursor: 'pointer',
                    },
                  }}
                >
                  <RiPlayLargeFill color="white" size={32} />
                  <Video url={`https://vimeo.com/${o.videoId}`} />
                </Box>
              )}
            </SwiperSlide>
          )
        })}
      </CustomSwiper>

      <CustomSwiper
        className="main-swiper"
        arrowSpacing={isMobile ? -30 : 0}
        spaceBetween={10}
        slidesPerView={1}
        slidesPerGroup={1}
        zoom={{
          maxRatio: MAX_ZOOM_RATIO,
        }}
        onZoomChange={(swiper, scale) => {
          setZoomScale(scale)
        }}
        modules={[Zoom, Navigation]}
        onSwiper={setMainSwiper}
        onSlideChangeTransitionStart={swiper => {
          thumbsSwiper?.slideToLoop(swiper.activeIndex)
          setCurrentSlider(swiper.activeIndex)
          setSource(
            mainSwiper?.wrapperEl.querySelector(
              '.source-image-' + swiper.activeIndex
            ) as HTMLImageElement
          )
        }}
        extraButtons={
          <>
            {!isFullScreen && (
              <FullScreenButton onClick={() => setFullScreen(true)}>
                {/* <RiZoomInLine /> */}
                <FullPlus />
              </FullScreenButton>
            )}

            {isFullScreen && (
              <ZoomButtons>
                <ZoomButton disabled={zoomScale >= MAX_ZOOM_RATIO} onClick={() => changeZoom(+1)}>
                  <RiZoomInLine />
                </ZoomButton>

                <ZoomButton disabled={zoomScale <= 1} onClick={() => changeZoom(-1)}>
                  <RiZoomOutLine />
                </ZoomButton>
              </ZoomButtons>
            )}
          </>
        }
      >
        {sliders.map((o, i) => {
          return (
            <SwiperSlide
              key={o.title}
              onClick={e => {
                if (e.detail === 2) {
                  setFullScreen(true)
                }
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                className="swiper-zoom-container"
                title={isFullScreen ? 'Double Click to Zoom' : undefined}
              >
                {o.type === 'image' ? (
                  <Image
                    className={'source-image-' + i}
                    src={o.image}
                    sx={{
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    loading={i > 5 ? 'lazy' : undefined}
                    alt={o.alt || o.title}
                  />
                ) : (
                  <Video url={`https://vimeo.com/${o.videoId}`} />
                )}
              </Box>
            </SwiperSlide>
          )
        })}

        <SwiperCursorZoom ref={cursor} />
      </CustomSwiper>
    </Wrapper>
  )
}

export default ProductDetailView
