/** @jsx jsx **/
import { jsx, Box, Button } from 'theme-ui'
import React, { FC } from 'react'
import { CollectionType, ProductItemComponentType } from '~/@types/models'
import { useFlyOutContext } from '~/containers/fly-out/contex'
import theme from '~/gatsby-plugin-theme-ui'
import FlyOutCard from '~/containers/fly-out/fly-out-card'
import FlyOutGroupItemInner from '~/containers/fly-out/fly-out-group-item-inner'

type Props = {
  variant: ProductItemComponentType
  collection: CollectionType
  product: ProductItemComponentType
  currentProduct: ProductItemComponentType
  active: boolean
  onToggle: (variant: ProductItemComponentType, collection: CollectionType) => void
}

const FlyOutGroupItem: FC<Props> = ({
  variant,
  collection,
  product,
  currentProduct,
  active,
  onToggle,
}) => {
  return (
    <Box as="li">
      <Button
        sx={{
          bg: 'transparent',
          border: 0,
          p: 0,
          textAlign: 'left',
          color: theme.colors.muted,
          width: '100%',
          '.hover-icon': {
            display: 'none',
          },
          '&:hover': {
            '.hover-icon': {
              display: 'block',
            },
            '.check-icon': {
              display: 'none',
            },
          },
        }}
        onClick={() => {
          onToggle(variant, collection)
        }}
      >
        <FlyOutGroupItemInner active={active} variant={variant} />
      </Button>
    </Box>
  )
}

export default React.memo(FlyOutGroupItem)
