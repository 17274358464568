// @ts-ignore
import { Text, SxStyleProp } from 'theme-ui'
import React, { FC } from 'react'

type Props = {
  sx?: SxStyleProp
}

const ShippingLabel: FC<Props> = ({ children, sx }) => {
  return (
    <Text
      as="span"
      sx={{
        display: `block`,
        lineHeight: 1.5,
        mt: 'sm',
        ...sx,
      }}
    >
      {children}
    </Text>
  )
}

export default ShippingLabel
