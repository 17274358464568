import styled, { css } from 'styled-components'

export const StyledCustomSwiper = styled.div`
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    // disable selection text
    user-select: none;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navigation-next,
  .navigation-prev {
    background: transparent;
  }
`
