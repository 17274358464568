/** @jsx jsx **/
import { jsx, Box, Grid } from 'theme-ui'
import React, { FC } from 'react'
import { CollectionType, ProductItemComponentType } from '~/@types/models'
import FlyOutGroupItem from '~/containers/fly-out/fly-out-group-item'
import theme, { space } from '~/gatsby-plugin-theme-ui'
import { H4, H6, Paragraph } from '~/styled/components/typography'
import { useFlyOutContext } from '../contex'

type Props = {
  collection: CollectionType
  currentProduct: ProductItemComponentType
}

const FlyOutGroup: FC<Props> = ({ collection, currentProduct }) => {
  const { toggleSelectedProductVariant, selectedItems } = useFlyOutContext()

  return (
    <Box as="li" mb={space.md2} id={'group-' + collection.shopifyId}>
      <H4
        style={{
          fontWeight: 500,
          textTransform: 'uppercase',
        }}
      >
        {collection.title}
      </H4>
      <Box
        as="ul"
        sx={{
          variant: 'variants.list.none',
        }}
      >
        {collection.products.map(product => (
          <Box as="li" key={`product-${product.title}`}>
            {collection.products.length > 1 && (
              <React.Fragment>
                <H6
                  style={{
                    fontWeight: 700,
                    marginBottom: 24,
                  }}
                >
                  {product.title}
                </H6>

                <Paragraph
                  style={{
                    lineHeight: '24px',
                    letterSpacing: '0.5px',
                  }}
                >
                  {product.description}
                </Paragraph>
              </React.Fragment>
            )}

            <Grid
              columns={[4, 'repeat(auto-fit, minmax(max-content, 130px))']}
              sx={{
                columnGap: theme.space.md,
                rowGap: theme.space.md,
                variant: 'variants.list.none',
                mb: space.l,
              }}
            >
              {product.productSwatches.products.map(variant => {
                const active = selectedItems.includes(variant.cheapestVariant.shopifyId)

                return (
                  <FlyOutGroupItem
                    key={variant.id}
                    active={active}
                    variant={variant}
                    collection={collection}
                    product={product}
                    currentProduct={currentProduct}
                    onToggle={toggleSelectedProductVariant}
                  />
                )
              })}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default React.memo(FlyOutGroup)
