import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { fitAndPosition } from 'object-fit-math'
import { HTML } from 'shopify-storefront-api-typings'

function getRect(
  originalSize: { width: number; height: number },
  childSize: { width: number; height: number }
) {
  const filled = fitAndPosition(originalSize, childSize, 'contain')

  return {
    top: filled.y,
    left: filled.x,
    width: filled.width,
    height: filled.height,
  }
}

function getCursorPos(e: MouseEvent, source: HTMLImageElement) {
  let a,
    x = 0,
    y = 0

  const parentSize = source.parentElement!.getBoundingClientRect()

  const sourceSize = source.getBoundingClientRect()

  const fitted = getRect(parentSize, sourceSize)

  x = e.pageX - sourceSize.left - fitted.left - window.scrollX
  y = e.pageY - sourceSize.top - fitted.top - window.scrollY

  return { ...fitted, x: x, y: y }
}

export function useMouseOverZoom(
  target: React.RefObject<HTMLDivElement>,
  cursor: React.RefObject<HTMLElement>,
  sizeWidth = 160,
  sizeHeight = 190
) {
  const [source, setSource] = useState<HTMLImageElement>()

  const [mouse, setMouse] = useState({ x: 0, y: 0, isActive: false })

  const targetDiv = useRef<HTMLDivElement | null>(null)

  const createTarget = useCallback(() => {
    if (source && target.current) {
      target.current.style.overflow = 'hidden'
      target.current.style.width = '100%'
      target.current.style.height = 'auto'
      target.current.style.backgroundColor = '#fff'
      target.current.innerHTML = ''

      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.pointerEvents = 'none'
      div.style.left = '0px'
      div.style.top = '0px'

      const img = document.createElement('img')
      img.src = source.src
      img.style.width = '100%'
      img.style.height = '100%'
      img.style.objectFit = 'contain'

      div.appendChild(img)
      target.current.appendChild(div)
      targetDiv.current = div
    }
  }, [source])

  useEffect(() => {
    if (source) {
      createTarget()
      const handleMouseMove = (e: MouseEvent) => {
        const pos = getCursorPos(e, source)

        let x = pos.x - sizeWidth / 2
        let y = pos.y - sizeHeight / 2

        if (x > source.width - sizeWidth + pos.left) {
          x = source.width - sizeWidth + pos.left
        }

        if (x < pos.left) {
          x = pos.left
        }

        if (y > source.height - sizeHeight + pos.top) {
          y = source.height - sizeHeight + pos.top
        }

        if (y < pos.top) {
          y = pos.top
        }

        setMouse({
          x,
          y,
          isActive: true,
        })
      }

      const handleMouseOut = (e: MouseEvent) => {
        setMouse(o => ({ ...o, isActive: false }))
      }

      source.addEventListener('mousemove', handleMouseMove)
      source.addEventListener('mouseout', handleMouseOut)
      return () => {
        source?.removeEventListener('mousemove', handleMouseMove)
        source?.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [source, createTarget])

  const zoomBounds = useMemo(() => {
    return {
      left: mouse.x,
      top: mouse.y,
      width: sizeWidth,
      height: sizeHeight,
      isActive: mouse.isActive,
    }
  }, [mouse.x, mouse.y, mouse.isActive])

  useEffect(() => {
    const { left, top, width, height, isActive } = zoomBounds

    if (cursor.current) {
      const offset = 2

      cursor.current.style.left = `${left + offset}px`
      cursor.current.style.top = `${top + offset}px`
      cursor.current.style.width = `${width}px`
      cursor.current.style.height = `${height}px`
      cursor.current.style.display = isActive ? 'block' : 'none'
    }

    if (source && target.current && targetDiv.current) {
      target.current.style.aspectRatio = `${width}/${height}`
      target.current.style.display = isActive ? 'block' : 'none'
    }
  }, [source, zoomBounds])

  useEffect(() => {
    if (source && target.current && targetDiv.current) {
      const { left, top, width, height } = zoomBounds

      const cx = target.current.offsetWidth / width
      const cy = target.current.offsetHeight / height

      targetDiv.current.style.width = `${source!.width * cx}px`
      targetDiv.current.style.height = `${source!.height * cy}px`

      targetDiv.current.style.left = '-' + left * cx + 'px'
      targetDiv.current.style.top = '-' + top * cy + 'px'
    }
  }, [zoomBounds, source])

  return {
    setSource,
    createTarget,
  }
}
