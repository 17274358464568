import styled from 'styled-components'
import classnames from 'classnames'
import { FONTS } from '~/styled/components/typography'
import { StyledProductLinks } from './product-links/styles'

interface GridTemplateAreasProps {
  $fullScreen: boolean
}

export const GridTemplateAreas = styled.div<GridTemplateAreasProps>`
  display: grid;
  padding: 20px 0;

  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 440px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  grid-template-areas:
    'product-container product-container'
    'product-carousels product-carousels';

  .area-product-container {
    display: grid;

    grid-template-areas: 'product-view product-details';
    grid-template-columns: 1fr 440px;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    .area-product-details {
      position: relative;
    }

    .area-product-view {
      position: sticky;
      top: 36px;
      align-self: baseline;
      ${({ $fullScreen }) => $fullScreen && 'z-index: 20000'};
    }
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    grid-template-areas:
      'product-container product-container'
      'product-carousels product-carousels';
    grid-template-columns: 1fr;

    .area-product-container {
      display: grid;
      grid-template-areas: 'product-view' 'product-details';
      grid-template-columns: 1fr;

      .area-product-view {
        position: static;
        ${StyledProductLinks} {
          display: none;
        }
      }
    }
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.tablet} {
    grid-template-areas:
      'product-container product-container'
      'product-carousels product-carousels';
    grid-template-columns: 1fr;

    .area-product-container {
      display: grid;
      grid-template-areas: 'product-view' 'product-details';
      grid-template-columns: 1fr;

      .area-product-view {
        position: static;
        ${StyledProductLinks} {
          display: none;
        }
      }
    }
  }
`

interface GridAreaProps {
  $area: string
}

export const GridArea = styled.div.attrs<GridAreaProps>(({ $area, className }) => ({
  className: classnames('area-' + $area, className),
}))`
  grid-area: ${({ $area }) => $area || 'default'};
  min-width: 0;
`
