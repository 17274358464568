/** @jsx jsx */
import { jsx, Grid, Box, Text, BoxProps, SxStyleProp, Image } from 'theme-ui'
import { FC } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'
import urls from '~/common/urls'
import ColorSwatch from '~/components/product-detail-panel/color-swatch'
import { Color } from '~/components/product-detail-panel/types'
import useLinkState from '~/hooks/use-link-state'
import { useTooltip } from '../parameters/color-parameter/color-tooltip'

type Props = BoxProps & {
  colors: readonly Color[]
  sxProps?: SxStyleProp
  productHandle?: string
}

const ColorsList: FC<Props> = ({ colors: colorsBase, sxProps, productHandle }) => {
  const { comesFromSaleCollections } = useLinkState()
  const colorsOnSale: readonly Color[] =
    colorsBase.filter(
      color => color.cheapestVariant?.price?.amount < color.cheapestVariant?.compareAtPrice?.amount
    ) || []

  const colors = comesFromSaleCollections ? colorsOnSale : colorsBase
  const { Tooltip, createdContent, tooltipId } = useTooltip({ id: 'color-box-link' })

  /**
   * do we want an active state?
   * .css-ri42mv-ImageWrapper {
        box-sizing: border-box;
        padding: 2px;
        border: 1px solid #2B2B2B;
    }
   */

  if (colors.length === 0) {
    return null
  }

  return (
    <Box sx={{ ...sxProps, width: 'fit-content' }} className="colors-list">
      {/* <Text
        sx={{
          textTransform: `uppercase`,
          color: `text`,
          display: `block`,
          mb: [space.gridGap1],
        }}
      >
        More colors
      </Text> */}
      <Grid columns={[5, null, null, 6]} gap={[space.sm, null, null, space.gridGap1]}>
        {colors.map((color, idx) => (
          <Box key={color.title + idx}>
            <Box
              className="color-box-link"
              data-tooltip-id={tooltipId}
              data-tooltip-content={createdContent(
                color.colorName ?? color?.titleRaw ?? color?.title,
                color.mainImageSwatch?.originalSrc
              )}
              data-tooltip-delay-show={500}
              data-tooltip-delay-hide={500}
              style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)' }}
            >
              <ColorSwatch
                // @ts-ignore
                color={color}
                active={color.handle === productHandle}
                to={`${urls.pages.productDetail}/${color.handle}`}
              />
            </Box>
          </Box>
        ))}
        <Tooltip />
      </Grid>
    </Box>
  )
}

export default ColorsList
