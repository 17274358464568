import React, { FC, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ProductItemComponentType, ProductVariantType, SiteMetaDataType } from '~/@types/models'
import urls from '~/common/urls'

type QueryResult = {
  site: {
    siteMetadata: Pick<SiteMetaDataType, 'title' | 'siteUrl'>
  }
}

const query = graphql`
  query ProductRichSnippet {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantType
}

const ProductRichSnippet: FC<Props> = ({ product, variant }) => {
  const {
    site: {
      siteMetadata: { title, siteUrl },
    },
  } = useStaticQuery<QueryResult>(query)

  const offers = useMemo(() => {
    return product.variants.map(productVariant => {
      const availability = !productVariant.currentlyNotInStock
        ? 'http://schema.org/InStock'
        : 'http://schema.org/OutOfStock'

      return {
        '@type': 'Offer',
        priceCurrency: 'USD',
        google_product_category: product.productType,
        price: `${productVariant.price.amount}`,
        priceValidUntil: '2021-06-17',
        availability: `"${availability}"`,
        itemCondition: 'http://schema.org/NewCondition',
        mpn: `"${productVariant.shopifyId2}"`,
        sku: `"${productVariant.sku}"`,
        name: `"${productVariant.title}"`,
        url: `"${siteUrl}${urls.pages.productDetail}/${product.handle}"`,
        seller: {
          '@type': 'Organization',
          name: `"${title}"`,
        },
      }
    })
  }, [product, variant, title, siteUrl])

  const data = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    description: '',
    sku: variant.sku,
    name: product.title,
    google_product_category: product.productType,
    itemCondition: 'http://schema.org/NewCondition',
    offers,
    brand: {
      '@type': 'Brand',
      name: title,
    },
  }

  return (
    // eslint-disable-next-line react/no-danger
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
  )
}

export default ProductRichSnippet
