/** @jsx jsx */
import { jsx, Heading, Text, Box, Image, Button } from 'theme-ui'
import { FC } from 'react'
import { ImageType, ProductItemComponentType, ShopifyImageSource } from '~/@types/models'
import { Link, navigate } from 'gatsby'
import ImageWrapper from '~/components/image-wrapper'
import theme from '~/gatsby-plugin-theme-ui'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import urls from '~/common/urls'
import CompareAtPrice from '~/components/compare-at-price'
import { addScaleClasses, isNoScale, imageClassByType, scaleImageStyles } from '~/utils/product'
import { ISimpleProductType } from '~/context/products'
import useMainProductImage from '~/hooks/use-main-product-image'

type OutputType = {
  mainImage: any
  type: string
}

type Props = {
  variant?: string
  product: ISimpleProductType
  imageType?: 'productLifestyle' | 'productOverhead' | 'productFront'
  image?: ShopifyImageSource
  trackLocation?: string
}

const imageSizeMap: Record<string, string> = {
  FULLSCREEN: 'productLifestyle',
  OVERHEAD: 'productOverhead',
  FRONT: 'productFront',
}

export const ProductCardSimple: FC<Props> = props => {
  const { variant = 'primary', product, imageType = 'productFront', image, trackLocation } = props
  const imageSizesFromProduct = useMainProductImage({
    product,
  })

  const mainImage = image || imageSizesFromProduct.mainImage
  const type = imageType || imageSizesFromProduct.type

  return (
    <Box
      id="productItem"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: [25, 25, 25, 25, 25],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bg: 'bg',
          width: ['90%', '90%'],
        }}
      >
        {mainImage && (
          <Button
            sx={{
              all: 'unset',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (typeof window !== 'undefined' && trackLocation) {
                window.hj('event', `Product Carousel Click - ${trackLocation || ''}`)
              }
              navigate(`${urls.pages.productDetail}/${product.handle}`)
            }}
          >
            <Image
              src={mainImage.srcMobile}
              loading="lazy"
              variant={type || imageSizeMap[type]}
              alt={mainImage.altText || product.title || 'Product Image'}
              sx={{
                aspectRatio: '303/209',
                objectFit: 'cover',
                p: '5px',
                height: 'auto !important',
                maxHeight: ['235px', '100%'],
              }}
            />
          </Button>
        )}
      </Box>
      <Box
        as="header"
        sx={{
          zIndex: 1,
          display: 'block',
          textAlign: variant === 'ternary' ? 'left' : 'center',
          px: theme.space.md1,
        }}
      >
        <Heading
          as="h3"
          sx={{
            mb: theme.space.sm * 1.5,
            fontSize: ['23px !important'],
            color: '#2b2b2b',
          }}
        >
          {product.title}
        </Heading>
      </Box>
    </Box>
  )
}
