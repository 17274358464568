/** @jsx jsx */
// @ts-ignore
import { jsx, Flex, Text, SxStyleProp, Box, Image } from 'theme-ui'
import React, { FC, Fragment, Ref } from 'react'
import {
  SelectedValue,
  ColorOption,
  Option,
  ColorOptionWithAvailability,
} from '~/components/parameters/color-parameter/types'
import { ColorBoxV2 } from '~/componentsV2/colors/colorBox'
import { useTooltip } from './color-tooltip'

type Props = {
  listTitle: string
  listTitleSx?: SxStyleProp
  options: readonly ColorOptionWithAvailability[]
  selectedValue: SelectedValue
  onHoverOption: (optionRef: Ref<HTMLDivElement>, colorOption: ColorOption, out?: boolean) => void
  onChange: (colorOption: Option, title: string) => void
  optionRef: Ref<HTMLDivElement>
  option: Option
}

const OptionsList: FC<Props> = ({
  options,
  listTitle,
  listTitleSx,
  selectedValue,
  onHoverOption,
  onChange,
  optionRef,
  option,
}) => {
  // const preOrder =
  //   typeof listTitle === 'string' ? listTitle.indexOf('Expected to Ship Expected') > -1 : false
  // const checkPreOrder = (currentlyNotInStock: boolean) => {
  //   if (preOrder && currentlyNotInStock) {
  //     return false
  //   } else if (preOrder) {
  //     return false
  //   }
  //
  //   return currentlyNotInStock
  // }

  const { Tooltip, createdContent, tooltipId } = useTooltip({ id: 'product-color-swatch' })

  return (
    <Fragment>
      {/* <ShippingLabel
        sx={{
          mt: 0,
          minHeight: 24,
          ...listTitleSx,
        }}
      >
        {listTitle}
      </ShippingLabel> */}
      <Flex
        sx={{
          flexWrap: 'wrap',
          mt: 'sm',
          gap: '10px',
          position: 'relative',
          maxHeight: '120px',
        }}
      >
        {options.map((colorOption, index) => {
          return (
            <Box
              sx={{
                width: '55px',
                height: '55px',
                '@media screen and (max-width: 640px)': {
                  width: '40px',
                  height: '40px',
                },
              }}
            >
              <Box
                className="color-box-link"
                data-tooltip-id={tooltipId}
                data-tooltip-content={createdContent(
                  colorOption.title,
                  colorOption.imageTip ?? colorOption.image
                )}
                style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)' }}
              >
                <ColorBoxV2
                  key={`color-box-${Math.random()}`}
                  value={colorOption.title}
                  selectedValue={selectedValue}
                  currentlyNotInStock={colorOption.currentlyNotInStock}
                  onMouseOver={() => {
                    if (onHoverOption) {
                      onHoverOption(optionRef, colorOption)
                    }
                  }}
                  onMouseLeave={() => {
                    if (onHoverOption) {
                      onHoverOption(optionRef, colorOption, false)
                    }
                  }}
                  onClick={() => onChange(option, colorOption.title)}
                  src={colorOption.image}
                  alt={String(colorOption.title)}
                />
              </Box>
            </Box>
          )
        })}

        <Tooltip />
      </Flex>
    </Fragment>
  )
}

export default OptionsList
