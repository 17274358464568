import React, { Fragment, useState } from 'react'
import { space, theme } from '~/gatsby-plugin-theme-ui'
import { Box, Text, Button as ThemeButton } from 'theme-ui'
import { ProductItemComponentQuery, ProductVariantType } from '~/@types/models'

import { Button } from '~/components/button'
import Dialog from '~/components/dialog'
import { GoogleSheetForm } from '~/utils/googlesheetForm'

interface Props {
  product: ProductItemComponentQuery
  productVariant: ProductVariantType
  showText?: boolean
}

export const AntiqueRugsInfos = ({ product, productVariant, showText = true }: Props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const handleClick = () => {
    setShowDialog(!showDialog)
  }

  const productName = `${product.title}, ${product.metafields?.origin}, ${product.metafields?.exactSize}`
  const productImage = product.images[0]

  return (
    <Fragment>
      {showText && (
        <Box
          sx={{ my: [space.md], display: 'flex', flexDirection: 'column' }}
          className="antique-rug-info"
        >
          <Text
            className="antique-rug-info-title"
            sx={{
              fontSize: '16px',
              my: [space.md],
              mb: [space.xs],
              color: 'text',
            }}
          >
            {product.metafields.origin} | {product.metafields.exactSize}
            <br />
          </Text>
          <div>
            <Text
              sx={{
                fontSize: '16px',
                my: [space.md],
                color: 'text',
              }}
            >
              {productVariant.sku}
            </Text>
            {' | '}
            <ThemeButton
              sx={{
                all: 'unset',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={handleClick}
            >
              <Text
                sx={{
                  fontSize: '16px',
                  mt: [space.l],
                  color: 'strokes',
                }}
              >
                PRICE UPON REQUEST
              </Text>
            </ThemeButton>
          </div>
        </Box>
      )}

      <Box sx={{ mt: [space.l, null, space.l3] }}>
        <Box
          sx={{
            mt: [theme.space.l, null, theme.space.l3],
          }}
        >
          <Button
            sxProps={{
              width: ['100%'],
            }}
            label="Request Information"
            onClick={handleClick}
          />
          <Dialog showDialog={showDialog} close={handleClick} title="Antique Rug Inquiry">
            <Box p={theme.space.gridGap3}>
              <GoogleSheetForm
                productName={productName}
                productImage={productImage}
                additionalFields={{
                  ar: productVariant.sku,
                  product_link: 'https://bensoleimani.com/products/' + product.handle,
                }}
              />
            </Box>
          </Dialog>
        </Box>
      </Box>
    </Fragment>
  )
}
