/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import { Maybe } from '~/@types/models'
import Pdf from '~/components/icons/pdf'
import Link from '~/components/link'
import { space } from '~/gatsby-plugin-theme-ui'

type Link = {
  href: Maybe<string>
  title: string
}

type Props = {
  links: Link[]
}

type ListItemProps = {
  to: string
}

const ListItem = ({ to, ...props }: ListItemProps) => (
  <Flex
    as="li"
    sx={{
      mb: [space.sm1],
      alignItems: 'center',
    }}
  >
    <Box as="span">
      <Pdf width="30px" />
    </Box>

    <Link
      to={to}
      variant="nav"
      {...props}
      target="_blank"
      rel="noreferrer noopener"
      isExternal
      sx={{
        fontSize: [2, null, 3],
        flex: 1,
        display: 'inline-block',
      }}
    />
  </Flex>
)

const Files = ({ links }: Props) => {
  return (
    <Fragment>
      <Flex
        as="ul"
        sx={{
          listStyleType: 'none',
          m: 0,
          p: 0,
          flexDirection: 'column',
        }}
      >
        {links.map(link => (
          <ListItem to={link.href ?? ``} key={link.title}>
            {link.title}
          </ListItem>
        ))}
      </Flex>
    </Fragment>
  )
}

export default Files
