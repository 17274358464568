/** @jsx jsx **/
import { jsx, IconButton, SxStyleProp } from 'theme-ui'
import { FC } from 'react'
import VisuallyHidden from '@reach/visually-hidden'

type Props = {
  sxProps?: SxStyleProp
  close(): void
}

const CloseButton: FC<Props> = ({ close, sxProps }) => {
  return (
    <IconButton
      className="close-button"
      onClick={close}
      sx={{
        position: 'absolute',
        right: 32,
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        bg: 'transparent',
        width: 48,
        height: 48,
        fontSize: 30,
        border: '1px solid',
        color: 'text',
        ...sxProps,
      }}
    >
      <VisuallyHidden>Close</VisuallyHidden>
      <span aria-hidden>×</span>
    </IconButton>
  )
}

export default CloseButton
