/** @jsx jsx **/
import { Formik } from 'formik'
import { useState, FC, useRef } from 'react'
import { jsx, Box, Label, Input, Textarea } from 'theme-ui'
import { Button } from '~/components/button'
import LoadingForm from '~/components/loading-form'

function show_error(error) {
  return <div style={{ color: 'red' }}>{error}</div>
}

function ConfirmationScreen() {
  return (
    <Box>
      <h1>Thank you for your interest.</h1>
      <p>Our Antique Rug Concierge will assist you shortly.</p>
    </Box>
  )
}

type Props = {
  productName?: string
  productImage?: any
  additionalFields?: Record<string, string>
}

const GoogleSheetForm: FC<Props> = ({ productName, productImage, additionalFields }) => {
  const formRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState({
    screen: 'form',
  })

  const onSubmit = (values, { setSubmitting }) => {
    setState({
      screen: 'loading',
    })

    const form_data = new FormData()
    const date = new Date()

    Object.keys(values).forEach(key => {
      form_data.append(key, values[key])
    })
    form_data.append('inquiry_date', date.toDateString())
    form_data.append('image', `=IMAGE("${productImage.srcDesktop}")`)

    const scriptUrl = process.env.GATSBY_GOOGLE_APPSCRIPT_URL_ANTIQUE_RUGS as string

    fetch(scriptUrl, {
      method: 'POST',
      body: form_data,
    }).then(res => {
      setState({
        screen: 'confirmation',
      })
      setSubmitting(false)
    })
  }

  return (
    <div>
      {state.screen === 'form' && (
        <Formik
          initialValues={{
            product_name: productName,
            email: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            company_name: '',
            notes: '',
            ...additionalFields,
          }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Required'
            }

            if (!values.first_name) {
              errors.first_name = 'Required'
            }

            if (!values.last_name) {
              errors.last_name = 'Required'
            }
            return errors
          }}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Box ref={formRef} as="form" onSubmit={handleSubmit}>
              <Label htmlFor="product_name">PRODUCT NAME</Label>
              <Input
                name="product_name"
                id="product_name"
                mb={28}
                disabled
                value={values.product_name}
              />
              {additionalFields
                ? Object.keys(additionalFields).map((key, i) => (
                    <Input
                      key={i}
                      name={key}
                      id={key}
                      disabled
                      value={additionalFields[key]}
                      sx={{ display: 'none' }}
                    />
                  ))
                : null}

              <Label htmlFor="email">EMAIL*</Label>
              {errors.email && touched.email && show_error(errors.email)}
              <Input
                type="email"
                name="email"
                id="email"
                mb={28}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              <Label htmlFor="first_name">FIRST NAME*</Label>

              {errors.first_name && touched.first_name && show_error(errors.first_name)}
              <Input
                name="first_name"
                id="first_name"
                mb={28}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
              />

              <Label htmlFor="last_name">LAST NAME*</Label>
              {errors.last_name && touched.last_name && show_error(errors.last_name)}
              <Input
                name="last_name"
                id="last_name"
                mb={28}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
              />

              <Label htmlFor="phone_number">PHONE NUMBER</Label>
              <Input
                name="phone_number"
                id="phone_number"
                mb={28}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone_number}
              />

              <Label htmlFor="company_name">COMPANY NAME</Label>
              <Input
                name="company_name"
                id="company_name"
                mb={28}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company_name}
              />

              <Label htmlFor="notes">NOTES(OPTIONAL)</Label>
              <Textarea
                sx={{
                  'background-color': '#F8F8F8',
                  resize: 'none',
                  minHeight: 200,
                  p: '12px 15px',
                  lineHeight: 'body',
                  fontSize: '18px',
                  fontFamily: 'body',
                  fontWeight: 'body',
                }}
                name="notes"
                id="notes"
                rows={8}
                mb={28}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
              />

              <Button disabled={isSubmitting} sxProps={{ width: ['100%'] }} label="Submit" />
            </Box>
          )}
        </Formik>
      )}

      {state.screen === 'confirmation' && <ConfirmationScreen />}

      {state.screen === 'loading' && <LoadingForm />}
    </div>
  )
}

export { GoogleSheetForm }
