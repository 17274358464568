import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const FullPlus: FC<Props> = ({ ...rest }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 3V5H4V9H2V3H8ZM2 21V15H4V19H8V21H2ZM22 21H16V19H20V15H22V21ZM22 9H20V5H16V3H22V9Z"
      fill="currentColor"
    />
    <path
      d="M11.2857 11.2857V7H12.7143V11.2857H17V12.7143H12.7143V17H11.2857V12.7143H7V11.2857H11.2857Z"
      fill="currentColor"
    />
  </svg>
)

export default FullPlus
