import React, { FC, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ProductItemComponentType, ProductVariantType, SiteMetaDataType } from '~/@types/models'
import urls from '~/common/urls'

type QueryResult = {
  site: {
    siteMetadata: Pick<SiteMetaDataType, 'title' | 'siteUrl'>
  }
}

const query = graphql`
  query ViewedProductSnippet {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantType
}

const ViewedProductSnippet: FC<Props> = ({ product, variant }) => {
  const {
    site: {
      siteMetadata: { title, siteUrl },
    },
  } = useStaticQuery<QueryResult>(query)
  const viewProduct = (_learnq: any) => {
    const item = {
      ProductName: product.title,
      ProductID: product.id,
      SKU: variant.sku,
      Categories: [product.productType],
      ImageURL: variant.image?.originalSrc,
      URL: `${siteUrl}${urls.pages.productDetail}/${product.handle}`,
      Brand: title,
      Price: variant.price.amount,
      CompareAtPrice: variant.compareAtPrice?.amount,
    }
    _learnq.push(['track', 'Viewed Product', item])
  }
  if (typeof _learnq !== 'undefined') {
    // setNewItemBool(false)
    viewProduct(_learnq)
  }
  return <> </>
}

export default ViewedProductSnippet
