import { Paragraph, Text } from 'theme-ui'
import React, { FC, Fragment } from 'react'
import ShippingLabel from '~/components/parameters/shipping-label'

type Props = {
  option: any
  title: string
}

const TitleSingle: FC<Props> = ({ option, title }) => (
  <Fragment>
    <Paragraph className="color-title">
      <span className="label">{option.label}</span>
      <span className="separator">|</span>
      <Text
        as="span"
        sx={{
          color: 'strokes',
          textTransform: 'uppercase',
        }}
      >
        {option.values[0]}
      </Text>
    </Paragraph>
    {/* {title && <ShippingLabel>{title}</ShippingLabel>} */}
  </Fragment>
)

export default TitleSingle
