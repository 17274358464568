// @ts-ignore

import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react'

import ProductDetailView from './product-detail-view'
import { GridArea, GridTemplateAreas } from './styles'
import { SwiperCanvasZoom } from './product-detail-view/styles'
import { useProductDetail } from './Provider'
import ProductDetailPanel from './product-detail-panel'
import YouMightLikeSection from '../you-might-like-section'
import { ProductSubCollection } from '../product-detail/product-detail-subcollection'
import { ProductSubCollectionV2 } from '~/componentsV2/product/productCarrousel'
import { useRecentlyViewedProducts } from '~/context/products'
import ProductDetailsContainer from '../product-detail/product-details'
import UserTrackingSnippet from '~/components/klaviyo/logged-user-snippet'
import ViewedProductSnippet from '~/components/klaviyo/viewed-product-snippet'

import { useTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { ProductLinks } from './product-links'
import ProductRichSnippet from '~/components/rich-snippets/product-rich-snippet'
import { useCustomerContext } from '~/context/customer-context'

const ProductDetailV2 = () => {
  const targetCanvas = useRef<HTMLDivElement | null>(null)
  const targetTitle = useRef<HTMLDivElement | null>(null)

  const { customer } = useCustomerContext()

  const [isFullScreen, setIsFullScreen] = useState(false)

  const theme = useTheme()

  const isDesktop = useMediaQuery({ query: theme.mediaQueries.desktop })

  const {
    productSliders,
    productCategories,
    product,
    productVariant,
    productUtils: { isAntiqueRug, isUpholstery },
    tearsheetLink,
  } = useProductDetail()

  const { recentlyViewedProducts, addProductToRecentlyViewed } = useRecentlyViewedProducts()

  useEffect(() => {
    return () => {
      const title = product.variants[0].colorName
        ? product.title + '-' + product.variants[0].colorName.split('-')[0]
        : product.title

      addProductToRecentlyViewed({
        title,
        handle: product.handle,
        mainImage: product.mainImage || product.mainImageFront,
      })
    }
  }, [])

  const Sections = [] as ReactNode[]

  if (product.pairsWellWith) {
    Sections.push(
      <ProductSubCollection
        productVariant={productVariant}
        product={product}
        orderedArray={product.metafields.pairsWellWith}
        affixTileScroll={isDesktop && !Sections.length ? targetTitle : undefined}
        trackLocation="Pairs well with"
      />
    )
  }

  if (recentlyViewedProducts.filter(item => item.handle !== product.handle).length) {
    Sections.push(
      <ProductSubCollectionV2
        productVariant={productVariant}
        items={recentlyViewedProducts.filter(item => item.handle !== product.handle)}
        label="Recently Viewed"
        trackLocation="Recently Viewed"
        affixTileScroll={isDesktop && !Sections.length ? targetTitle : undefined}
      />
    )
  }

  if (productCategories?.length) {
    Sections.push(
      <ProductSubCollectionV2
        productVariant={productVariant}
        items={productCategories}
        label="You May Also Like"
        trackLocation="You May Also Like"
        affixTileScroll={isDesktop && !Sections.length ? targetTitle : undefined}
      />
    )
  }

  return (
    <Fragment>
      <GridTemplateAreas $fullScreen={isFullScreen}>
        <GridArea $area="product-container">
          <GridArea $area="product-view">
            <ProductDetailView
              onChangeFullScreen={setIsFullScreen}
              sliders={productSliders}
              target={targetCanvas}
              isRug={product.productType.toLocaleLowerCase().includes('rug')}
            />

            <ProductLinks style={{ paddingLeft: 100 }} />
            <div ref={targetTitle} />
          </GridArea>

          <GridArea $area="product-details">
            <div
              style={{
                position: 'sticky',
                top: '36px',
                zIndex: 10,
              }}
            >
              <SwiperCanvasZoom ref={targetCanvas} />
            </div>
            <ProductDetailPanel />
            <ProductDetailsContainer
              // defaultOpen={!isUpholstery ? 'PRODUCT SPECS' : undefined}
              product={product}
              productVariant={productVariant}
            />
          </GridArea>
        </GridArea>

        <GridArea $area="product-carousels">
          {product.youMightLike && !product.frequentlyPurchasedWith && (
            <YouMightLikeSection variant={productVariant} products={product.youMightLike} />
          )}

          {Sections.map((section, i) => (
            <React.Fragment key={i}>{section}</React.Fragment>
          ))}
        </GridArea>
      </GridTemplateAreas>
      <ProductRichSnippet variant={productVariant} product={product} />

      {customer && (
        <UserTrackingSnippet
          customer={customer}
          tracking={true}
          setTracking={(input: (tracking: boolean) => void) => input}
        />
      )}

      <ViewedProductSnippet variant={productVariant} product={product} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
    function saFirePixel() {
        try {
            var sa_exclude = document.URL.match('gift-card') || !document.querySelector('.add-to-cart-button') || document.querySelector('.add-to-cart-button[disabled]');
            if (sa_exclude) { return; }

            var sa_click = document.URL;
            if (!sa_click) { return; }

            var sa_image = document.querySelector('.breadcrumb__item .breadcrumb__link[href*="rugs"]')
                ? document.querySelector('.swiper-wrapper .source-image-1') && document.querySelector('.swiper-wrapper .source-image-1').src
                : document.querySelector('.swiper-wrapper .source-image-0') && document.querySelector('.swiper-wrapper .source-image-0').src;
            if (!sa_image) { return; }

            var sa_name = document.querySelector('.area-product-details h1').innerText;
            if (!sa_name) { return; }

            !function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('drt','DoM2bLErwYTnkaACcdhLh2', { '\${drt_title}': encodeURIComponent(sa_name), '\${drt_image}': encodeURIComponent(sa_image), '\${drt_click}': encodeURIComponent(sa_click)});
        } catch(e) {
            console.log(e);
        }
    }

    function saChangeOption() {
        setTimeout(saFirePixel, 2000);
        setTimeout(saAddOptionListeners, 2000);
    }

    function saAddOptionListeners() {
        try {
            var sa_product_options = document.querySelectorAll('.select-parameter .tag, .color-parameter button');
            sa_product_options.forEach(function(option) {
                option.addEventListener('click', saChangeOption);
            });
        } catch (e) {
            console.log(e);
        }
    }

    setTimeout(saFirePixel, 4000);
    setTimeout(saAddOptionListeners, 4000);
  `,
        }}
      />
    </Fragment>
  )
}

export default ProductDetailV2
