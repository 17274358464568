/** @jsx jsx **/
import { Heading, jsx, Box, Image, Grid, Divider } from 'theme-ui'
import React, { FC, Fragment } from 'react'
import { useFlyOutContext } from '~/containers/fly-out/contex'
import CloseButton from '~/components/close-button'
import theme, { space } from '~/gatsby-plugin-theme-ui'
import { H3, H4, Paragraph, SmallText } from '~/styled/components/typography'
import { CollectionType, ProductItemComponentType } from '~/@types/models'
import ImageWrapper from '~/components/image-wrapper'
import FlyOutRequestCustomQuote from '../fly-out-request-custom-quote'

interface FlyOutHeaderProps {
  collections: CollectionType[]
  currentProduct: ProductItemComponentType
}

const FlyOutHeader: FC<FlyOutHeaderProps> = ({ collections, currentProduct }) => {
  return (
    <Box my={space.sm1} px={[space.md1, null, space.md3]}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: 16,
        }}
      >
        <Box>
          <H3
            style={{
              textTransform: 'uppercase',
              marginBottom: 16,
            }}
          >
            Explore All Upholstery Options
          </H3>
          <Paragraph
            style={{
              margin: 0,
            }}
          >
            Select your complimentary swatch options.
          </Paragraph>
        </Box>
        <Box>
          <FlyOutRequestCustomQuote currentProduct={currentProduct} />
        </Box>
      </Box>

      <Divider mt={10} color="#EEEEEE" />

      <Paragraph
        style={{
          marginTop: 25,
          color: '#585858',
        }}
      >
        Jump to category:
      </Paragraph>
      <Grid
        gap={space.md}
        columns={[3, 'repeat(auto-fit, minmax(max-content, 130px))']}
        sx={{ marginTop: space.md }}
      >
        {collections.map(o => {
          return (
            <Box
              key={o.title}
              sx={{
                width: ['auto', '130px'],
                overflow: 'hidden',
                img: {
                  aspectRatio: ['85/60', '130/80'],
                  minWidth: '100%',
                  objectFit: 'cover',
                },
                cursor: 'pointer',
              }}
              onClick={() => {
                document.querySelector(`#group-${o.shopifyId}`)?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <Image
                  src={
                    o.metafields?.collectionCustomImageUrl ||
                    o.image?.originalSrc ||
                    o.products[0].mainImage.srcMobileSmall
                  }
                  alt={o.title || 'Product Image'}
                  loading="lazy"
                />
              </Box>
              <SmallText
                style={{
                  marginTop: '1px',
                  textTransform: 'uppercase',
                  wordBreak: 'break-word',
                }}
              >
                {o.title}
              </SmallText>
            </Box>
          )
        })}
      </Grid>
    </Box>
  )
}

export default React.memo(FlyOutHeader)
