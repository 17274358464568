/** @jsx jsx */
import { jsx, Text, Box, Input, Flex, SxStyleProp } from 'theme-ui'
import { FC, useCallback } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'

type Props = {
  onChange: (value: number) => void
  containerSx: SxStyleProp
}

const YardageInput: FC<Props> = ({ onChange, containerSx }) => {
  const updateValue = useCallback(
    (value: number) => {
      if (value < 3) {
        value = 3
      }
      const convertedValue = Number(value)
      const newValue = Math.max(0, convertedValue)
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <Flex
      sx={{
        ...containerSx,
      }}
    >
      <Text sx={{ marginRight: space.sm, alignSelf: 'center' }}>YARDS</Text>
      <Flex
        as={Box}
        sx={{
          alignSelf: 'center',
          mr: '2px',
          minWidth: 'small ? [46, null, 80] : 120',
          borderColor: 'strokes',
          bg: 'bg',
          color: 'text',
          height: 42,
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Input
          type="number"
          onChange={e => updateValue(e.target.value)}
          min="3"
          defaultValue="3"
          sx={{ maxHeight: 42, textAlign: 'center' }}
        ></Input>
      </Flex>
    </Flex>
  )
}

export default YardageInput
