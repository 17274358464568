export const hubspotFormIds = {
  contact: process.env.GATSBY_HUBSPOT_PORTAL_ID_CONTACT_FORM,
  customRugStudio: process.env.GATSBY_HUBSPOT_PORTAL_ID_CUSTOM_RUG_STUDIO_FORM,
  tradePortal: process.env.GATSBY_HUBSPOT_PORTAL_ID_TRADE_PORTAL,
  bespokeServices: process.env.GATSBY_HUBSPOT_PORTAL_ID_BESPOKE_SERVICES,
  designServices: process.env.GATSBY_HUBSPOT_PORTAL_ID_DESIGN_SERVICES,
  newsletterPage: process.env.GATSBY_HUBSPOT_PORTAL_ID_NEWSLETTER_PAGE,
  newsletterCatalog: process.env.GATSBY_HUBSPOT_PORTAL_ID_NEWSLETTER_CATALOG,
  newsletterFooter: process.env.GATSBY_HUBSPOT_PORTAL_ID_NEWSLETTER_FOOTER,
  customQuickShip: process.env.GATSBY_HUBSPOT_PORTAL_ID_CUSTOM_QUICKSHIP,
  customQuickShipRequestInformation:
    process.env.GATSBY_HUBSPOT_PORTAL_ID_CUSTOM_QUICKSHIP_REQUEST_INFORMATION,
  customQuote: process.env.GATSBY_HUBSPOT_PORTAL_ID_CUSTOM_QUOTE,
  customQuickShipArt: process.env.GATSBY_HUBSPOT_PORTAL_ID_CUSTOM_QUICKSHIP_ART,
  customQuickShipCraemerArtwork: process.env.GATSBY_HUBSPOT_PORTAL_ID_CUSTOM_QUICKSHIP_CREAMER_ART,
  antiqueRugs: process.env.GATSBY_HUBSPOT_PORTAL_ID_ANTIQUE_RUGS,
}
