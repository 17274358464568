/** @jsx jsx **/
import { jsx, Box } from 'theme-ui'
import React, { FC } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'

type Props = {}

const FlyOutGroupWrapper: FC<Props> = ({ children }) => {
  return (
    <Box
      as="ul"
      sx={{
        variant: 'variants.list.none',
        p: [space.md1, null, space.md3],
      }}
    >
      {children}
    </Box>
  )
}

export default FlyOutGroupWrapper
