// @ts-ignore
import { Box } from 'theme-ui'
import React, { FC, Fragment, useEffect, useState } from 'react'
import Dialog from '~/components/dialog'
import theme, { space } from '~/gatsby-plugin-theme-ui'
import { Button } from '~/components/button'
import { HubspotForm } from '~/utils/hubspotForm'
import { hubspotFormIds } from '~/common/hubspot'
import { useFlyOutContext } from '~/containers/fly-out/contex'
import { Select } from '~/components/select'
import { ProductItemComponentType } from '~/@types/models'

type Props = {
  currentProduct: ProductItemComponentType
}

const FlyOutRequestCustomQuote: FC<Props> = ({ currentProduct }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [selectedSize, setSelectedSize] = useState<string>('')
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  const sizes = currentProduct.options.find(option => option.name === 'Size')?.values

  useEffect(() => {
    if (sizes && !selectedSize) setSelectedSize(sizes[0])
  }, [])

  return (
    <Fragment>
      <Button
        sxProps={{
          backgroundColor: '#585858',
          borderColor: 'transparent',
        }}
        size="sm"
        label="Request a Custom Quote"
        onClick={open}
      />
      <Dialog
        id="request-custom-quote-dialog"
        showDialog={showDialog}
        close={close}
        title="Request a Custom Quote"
      >
        <Box p={theme.space.gridGap3}>
          <Box mb={space.inputGap}>
            {sizes && (
              <Select
                onChange={e => {
                  const upholsteryCustomQuoteSize = document.getElementsByName(
                    'specify_size__if_applicable_'
                  )[0] as HTMLInputElement
                  upholsteryCustomQuoteSize.value = e.target.value
                }}
                options={sizes}
                label="Size"
              />
            )}
          </Box>

          <HubspotForm
            formId={hubspotFormIds.customQuote as string}
            onReady={() => {
              const upholsteryCustomQuoteProductName = document.getElementsByName(
                'upholstery_custom_quote_product_name'
              )[0] as HTMLInputElement
              const upholsteryCustomQuoteSku = document.getElementsByName(
                'upholstery_custom_quote_sku'
              )[0] as HTMLInputElement

              const upholsteryCustomQuoteSize = document.getElementsByName(
                'specify_size__if_applicable_'
              )[0] as HTMLInputElement
              if (
                upholsteryCustomQuoteProductName &&
                upholsteryCustomQuoteSku &&
                upholsteryCustomQuoteSize
              ) {
                upholsteryCustomQuoteProductName.value = currentProduct.title
                upholsteryCustomQuoteSku.value = currentProduct.variants[0].sku.split('-')[0]
                upholsteryCustomQuoteSize.value = selectedSize
              }
            }}
          />
        </Box>
      </Dialog>
    </Fragment>
  )
}

export default FlyOutRequestCustomQuote
