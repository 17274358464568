/** @jsx jsx **/
import { jsx, Box } from 'theme-ui'
import { Themed } from '@theme-ui/mdx'
import { FC } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { Close } from '~/components/icons/close'

type Props = {
  title?: string
  dialogSize?: 880 | 560
  showDialog: boolean
  close(): void
  id?: string
  titlePosition?: 'left' | 'right' | 'center'
}

const Dialog: FC<Props> = ({
  children,
  showDialog,
  close,
  title,
  dialogSize = 880,
  id,
  titlePosition,
}) => {
  return (
    <DialogOverlay
      id={id}
      sx={{
        zIndex: 'dialog',
        background: 'hsla(0, 100%, 100%, 0.9)',
      }}
      isOpen={showDialog}
      onDismiss={close}
    >
      <DialogContent
        aria-label="Cart"
        aria-describedby="cart"
        sx={{
          boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
          p: 0,
          maxWidth: ['100%', null, dialogSize],
          width: '100%',
          m: [0, null, '10vh auto'],
        }}
      >
        <Box
          as="header"
          sx={{
            position: 'relative',
            minHeight: 32,
          }}
        >
          <button
            className="close-button"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              borderRadius: '50%',
              bg: 'transparent',
              width: 48,
              minWidth: 48,
              maxWidth: 48,
              height: 48,
              fontSize: 30,
              p: 0,
              m: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <Close
              sx={{
                width: 15,
                height: 15,
              }}
            />
          </button>
          {title && (
            <Themed.h2
              sx={{
                m: [0, 0, 0],
                p: 32,
                pr: [40, null, 100],
                bg: 'productBackground',
                textAlign: titlePosition || 'left',
              }}
            >
              {title}
            </Themed.h2>
          )}
        </Box>
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export default Dialog
