import React from 'react'
import { useProductDetail } from '../Provider'
import { StyledProductLinks } from './styles'
import { Link } from 'gatsby'
import CustomQuickShip from '~/components/custom-quick-ship'
import { hubspotFormIds } from '~/common/hubspot'
import { Text } from 'theme-ui'
import { useCustomerContext } from '~/context/customer-context'

interface ProductLinksProps {
  style?: React.CSSProperties
}

export const ProductLinks = ({ style }: ProductLinksProps) => {
  const {
    product,
    productUtils: { isAntiqueRug },
    tearsheetLink,
  } = useProductDetail()

  const { customerType, auth } = useCustomerContext()

  return (
    <StyledProductLinks style={style}>
      <a href="/pages/bespoke-services" target="_blank">
        <Text
          sx={{
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: ['inherit'],
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          CUSTOM INQUIRY
        </Text>
      </a>
      <span>|</span>
      <Link to="/pages/contract-design-studio" target="_blank">
        <Text
          sx={{
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: ['inherit'],
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          CONTRACT INQUIRY
        </Text>
      </Link>
      {!isAntiqueRug && !product.isCustomQuickship && (
        <>
          <span>|</span>
          <CustomQuickShip
            product={product}
            textLabel="REQUEST INFORMATION"
            formId={hubspotFormIds.customQuickShipRequestInformation}
            triggerProductName="product_name"
            activeText
          />
        </>
      )}
      {tearsheetLink && (
        <>
          <span>|</span>
          <a href={tearsheetLink} target="_blank" rel="noreferrer">
            <Text
              sx={{
                cursor: 'pointer',
                fontWeight: 400,
                fontSize: ['inherit'],
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              TEARSHEET
            </Text>
          </a>
        </>
      )}
    </StyledProductLinks>
  )
}
