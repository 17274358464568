import { ProductItemComponentType } from '~/@types/models'

type Props = {
  product: ProductItemComponentType
  overrideImage?: string
  overrideType?: string
}

type OutputType = {
  mainImage: any
  type: string
}

const isRug = (product: ProductItemComponentType) =>
  (product.tags && product.tags.includes('product-rug')) ||
  product.title.toLowerCase().includes('rug')

const useMainProductImage = ({ product, overrideImage, overrideType }: Props): OutputType => {
  let mainImage = product.mainImage
  let type = 'OVERHEAD'

  if (isRug(product)) {
    mainImage = product.mainImage
    type = 'OVERHEAD'
  } else if (product.mainImageFront) {
    mainImage = product.mainImageFront
    type = 'FRONT'
  }

  if (overrideImage) {
    mainImage = product[overrideImage]
  }

  if (overrideType) {
    type = overrideType
  }

  return {
    mainImage,
    type,
  }
}

export default useMainProductImage
