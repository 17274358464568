/** @jsx jsx */
import { Box, jsx, SxStyleProp, Text } from 'theme-ui'
import { ChangeEvent, useMemo } from 'react'
import { Checkbox } from '~/components/checkbox'
import { useProductDetail } from '~/containers/product-detail-v2/Provider'

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => any
  value: boolean
  sxProps?: SxStyleProp
  smallCheckbox?: boolean
}

const PreOrderBundleMessage = () => {
  const { currentBundle, productAvaliable } = useProductDetail()
  const message = useMemo(() => productAvaliable.getColorParameterTitle(), [productAvaliable])

  if (!currentBundle && message)
    return <Text sx={{ fontSize: '12px', textTransform: 'uppercase', mb: '8px' }}>{message}</Text>

  if (!currentBundle && !currentBundle?.preOrderMessage) return null

  return currentBundle?.preOrderMessage.map(message => (
    <Box key={message.title}>
      <Text sx={{ textTransform: 'uppercase', fontSize: '12px' }}>{message.title}</Text>
      <br />
      <Text sx={{ fontSize: '12px' }}>Expected to Ship {message.preOrderMessage}</Text>
    </Box>
  ))
}

const PreOrderNote = () => {
  const { avaliableControl } = useProductDetail()

  return (
    <div style={{ position: 'relative' }}>
      <PreOrderBundleMessage />
      <div style={{ cursor: 'pointer', display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Checkbox
          label={
            <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              I agree to the{' '}
              <a
                href="/pages/faqs#faq-how-does-pre-order-work"
                style={{ fontWeight: 'bold' }}
                target="_blank"
              >
                Pre-order Terms
              </a>
            </span>
          }
          // @ts-ignore
          onChange={avaliableControl.handlePreorderAgreement}
          value={avaliableControl.preOrderAgreement}
        />
      </div>
    </div>
  )
}

export default PreOrderNote
