import React from 'react'
import { H4 } from '~/styled/components/typography'
import { ProductTitleWrapper, ProductPriceRow } from './styles'
import { Tag } from '~/componentsV2/tag'
import { Box, Link } from 'theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import { all } from 'ramda'

// const memorialDayRugs = ['CONTEMPORARY RUGS', 'TRADITIONAL RUGS', 'MOROCCAN RUGS']
// const isMemorialDayRug = memorialDayRugs.includes(product?.productType)
// // discount message for trade
// const messages = {
//   trade: '25% OFF TRADE RUG SALE | FINAL SALE',
//   memorialDayRug: '20% OFF RUG SALE | FINAL SALE',
// }

// const showPriceWithoutDiscount = productPrice.isTradeAccountLabelVisible || isMemorialDayRug
// const showTradePrice = productPrice.isTradeAccountLabelVisible && !isMemorialDayRug

interface Props {
  currentPrince?: any
  inStock?: boolean
}
const ProductPriceContainer = ({ currentPrince, inStock }: Props) => {
  const { auth } = useCustomerContext()

  const PriceTagInfo = ({ children, link }: { children: React.ReactNode; link?: string }) => {
    if (link) {
      return (
        <a href={link}>
          <Tag
            bgColor="transparent"
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              textDecoration: 'underline',
              padding: '5px 0',
              textUnderlineOffset: '3px',
            }}
          >
            {children}
          </Tag>
        </a>
      )
    }

    return (
      <Tag bgColor="transparent" style={{ padding: '5px 0', minWidth: 'auto' }}>
        {children}
      </Tag>
    )
  }

  return currentPrince.visible ? (
    <ProductTitleWrapper>
      <ProductPriceRow>
        <H4
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {currentPrince.finalPrice}{' '}
        </H4>

        {currentPrince.isTradeAccountLabelVisible && (
          <H4>
            <s>{currentPrince.priceFormatted}</s>
          </H4>
        )}
        {currentPrince.isSale && (
          <H4>
            <s>{currentPrince.compareAtPriceFormatted}</s>
          </H4>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {currentPrince.compareAtPrice ? (
            <PriceTagInfo link="/pages/faqs#faq-what-are-the-terms-and-conditions-of-your-offers-and-promotions">
              FINAL SALE
            </PriceTagInfo>
          ) : null}
          {currentPrince.isTradeAccountLabelVisible ? (
            <PriceTagInfo>TRADE PRICE</PriceTagInfo>
          ) : null}
          {!currentPrince.currentlyNotInStock && inStock ? (
            <PriceTagInfo>IN STOCK</PriceTagInfo>
          ) : null}

          {!auth?.accessToken && !currentPrince.compareAtPrice && (
            <PriceTagInfo link="/sign-in">TRADE PRICE AVAILABLE</PriceTagInfo>
          )}
        </Box>
      </ProductPriceRow>
      {/* {isMemorialDayRug && !productPrice.isSale && (
            <Tag bgColor="transparent">
              {productPrice.isTradeAccountLabelVisible ? messages.trade : messages.memorialDayRug}
            </Tag>
          )} */}
    </ProductTitleWrapper>
  ) : null
}

export default ProductPriceContainer
