import React, { FC, MutableRefObject, useEffect, useMemo, useState } from 'react'
import { ProductItemComponentType, ProductItemType, ProductVariantType } from '~/@types/models'
import ProductFieldCarousel from '~/components/product-field-carousel'
import ProductItemSecondary from '~/components/product-item-secondary'
import Section from '~/components/section'
import { space } from '~/gatsby-plugin-theme-ui'
import { withPrefix } from 'gatsby'
import { ProductFieldCarouselV2 } from '../product-field-carousel'
import { ProductCardSimple } from '../productCard'
import { ISimpleProductType } from '~/context/products'

type Props = {
  items: ISimpleProductType[]
  productVariant: ProductVariantType
  orderedArray?: any[]
  label: string
  affixTileScroll?: MutableRefObject<HTMLDivElement | null>
  trackLocation?: string
}

export const ProductSubCollectionV2: FC<Props> = props => {
  const { items, label, affixTileScroll, trackLocation } = props

  return (
    <Section
      label={label.toUpperCase()}
      affixTileScroll={affixTileScroll}
      sxSection={{
        pt: '40px !important',
        mb: '40px !important',
      }}
      sxLabel={{
        mb: '24px !important',
        fontSize: ['20px !important', '28px !important'],
        color: '#2b2b2b',
        fontWeight: 500,
      }}
    >
      <ProductFieldCarouselV2
        items={items}
        GridItem={ProductCardSimple}
        carouselOnMobile={true}
        gridItemProps={{
          columns: [1, 2, null, 3, 3],
          gap: [space.gridGap3],
          trackLocation: trackLocation,
        }}
        trackLocation={trackLocation}
      />
    </Section>
  )
}
