/** @jsx jsx **/
import { Formik } from 'formik'
import { useState, FC, useRef, Fragment } from 'react'
import { jsx, Box, Label, Input, Textarea, Flex, Text } from 'theme-ui'
import { Button } from '~/components/button'
import LoadingForm from '~/components/loading-form'

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

interface FormFieldBase {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  type?: string
  value?: string
  props?: any
  after?: string
  join?: string
  validation?: string[]
  col?: Cols | Cols[]
}

interface FormField extends FormFieldBase {
  children?: FormFieldBase[]
}

interface FormMessage {
  title?: string
  description?: string
}

interface Props {
  formName: string
  description?: string
  fields: FormField[]
  message?: FormMessage
  buttonType?: string
  buttonTitle?: string
}

const VALIDATIONS = {
  email: {
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Invalid email address',
  },
  min: {
    regex: /.+/,
    message: 'This field is required',
  },
  max: {
    regex: /.+/,
    message: 'This field is required',
  },
}

function show_error(error: string) {
  return <div style={{ color: 'red' }}>{error}</div>
}

function ConfirmationScreen({
  title = 'Thank you for your interest.',
  description = 'Our team will assist you shortly.',
}: FormMessage) {
  return (
    <Box>
      <h1>{title}</h1>
      <p>{description}</p>
    </Box>
  )
}

const DynamicGoogleSheetForm: FC<Props> = ({
  fields,
  formName,
  message,
  description,
  buttonType,
  buttonTitle,
}) => {
  const formRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState({
    screen: 'form',
  })

  const initialValues = fields.reduce(
    (acc: Record<string, string | Record<string, string>>, field) => {
      if (field.children) {
        const values: Record<string, string> = {}
        field.children.forEach(child => {
          values[child.name] = child?.value || ''
        })

        acc[field.name] = values

        return acc
      }
      acc[field.name] = field?.value || ''
      return acc
    },
    {}
  )

  const requiredFields = fields.filter(
    field => field.required || field.children?.some(child => child.required)
  )

  const onSubmit = (values, { setSubmitting }) => {
    setState({
      screen: 'loading',
    })

    const form_data = new FormData()
    const date = new Date()

    Object.keys(values).forEach(key => {
      const field = fields.find(field => field.name === key)

      if (field?.children) {
        const join = field.join || ''
        const value: string[] = []

        field.children.forEach((child, index) => {
          let childValue = values[key]?.[child.name] || ''

          if (child.after) {
            childValue += child?.after
          }

          value.push(childValue)
        })

        form_data.append(key, value.join(join))
      } else {
        form_data.append(key, values[key])
      }
    })

    form_data.append('form_name', formName)
    form_data.append('request_date', date.toDateString())
    form_data.append('inquiry_date', date.toDateString())

    const scriptUrl = process.env.GATSBY_GOOGLE_APPSCRIPT_URL_CUSTOM_RUG_STUDIO as string

    fetch(scriptUrl, {
      method: 'POST',
      body: form_data,
    }).then(res => {
      setState({
        screen: 'confirmation',
      })
      setSubmitting(false)
    })
  }

  const mountCols = (col?: Cols | Cols[]) => {
    const colSpanText = (col: Cols) => {
      return `span ${col} / span ${col}`
    }
    const colsSpan = (cols: Cols) => {
      if (typeof cols === 'number') {
        return colSpanText(cols)
      } else if (cols === null) {
        return null
      }
    }
    if (!col) {
      return colSpanText(12)
    }

    if (Array.isArray(col)) {
      return col.map(colsSpan)
    } else {
      return [colSpanText(12), null, colSpanText(col)]
    }
  }

  return (
    <Box>
      {state.screen === 'form' && (
        <Fragment>
          {description ? (
            <Box sx={{ mb: '28px' }}>
              <Text>{description}</Text>
            </Box>
          ) : null}
          <Formik
            initialValues={initialValues}
            validate={values => {
              const errors = {} as Record<string, string>

              requiredFields.forEach(field => {
                if (!values[field.name] && !field.children) {
                  errors[field.name] = `${field.label} is required`
                } else if (field.children) {
                  field.children.forEach(child => {
                    if (!values[field.name]?.[child.name] && child.required) {
                      errors[field.name + '.' + child.name] = `${child.label} is required`
                    }
                  })
                }
              })

              Object.keys(values).forEach(key => {
                const field = fields.find(field => field.name === key)

                if (field?.validation && !field.children) {
                  field.validation.forEach(validation => {
                    if (!VALIDATIONS[validation].regex.test(values[key])) {
                      errors[key] = VALIDATIONS[validation].message
                    }
                  })
                }
              })

              return errors
            }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Box
                ref={formRef}
                as="form"
                onSubmit={handleSubmit}
                sx={{
                  display: 'grid',
                  gap: '20px',
                  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
                  input: {
                    mb: 0,
                    '&:disabled': {
                      opacity: 0.5,
                    },
                  },
                  '* label': {
                    textTransform: 'uppercase',
                  },
                }}
              >
                {fields.map((field, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: field.hidden ? 'none' : 'block',
                      mb: 0,
                      gridColumn: mountCols(field.col),
                    }}
                  >
                    <Label htmlFor={field.name}>
                      {field.label}
                      {field.required ? '*' : ''}
                    </Label>
                    {field.children ? (
                      <Flex sx={{ mb: 0, gap: '28px' }}>
                        {field.children.map((child, j) => {
                          const key = field.name + '.' + child.name
                          return (
                            <Box
                              key={j}
                              sx={{
                                position: 'relative',
                                flex: 1,
                                '&::after': {
                                  content: `"${child.name}"`,
                                  display: 'block',
                                  mt: '2px',
                                },
                              }}
                            >
                              <Input
                                name={key}
                                id={key}
                                type={child.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[field.name] ? values[field.name][child.name] : ''}
                                disabled={child.disabled}
                                {...child.props}
                              />
                              {(child.required || child.validation) &&
                                touched[key] &&
                                errors[key] &&
                                show_error(errors[key])}
                            </Box>
                          )
                        })}
                        {(field.required || field.validation) &&
                          touched[field.name] &&
                          errors[field.name] &&
                          show_error(errors[field.name])}
                      </Flex>
                    ) : (
                      <Box sx={{ mb: 0, gridColumn: mountCols(field.col) }}>
                        <Input
                          name={field.name}
                          id={field.name}
                          type={field.type}
                          mb={28}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[field.name]}
                          disabled={field.disabled}
                          sx={{
                            mb: 0,
                          }}
                        />
                        {(field.required || field.validation) &&
                          touched[field.name] &&
                          errors[field.name] &&
                          show_error(errors[field.name])}
                      </Box>
                    )}
                  </Box>
                ))}

                <Button disabled={isSubmitting} sxProps={{ width: ['100%'] }} label="Submit" />
              </Box>
            )}
          </Formik>
        </Fragment>
      )}

      {state.screen === 'confirmation' && <ConfirmationScreen {...message} />}

      {state.screen === 'loading' && <LoadingForm />}
    </Box>
  )
}

export { DynamicGoogleSheetForm }
