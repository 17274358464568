/** @jsx jsx */
// @ts-ignore
import { FC } from 'react'
import { jsx, Box, Flex } from 'theme-ui'
import BaseTypo from '~/components/base-typo'
import { ProductDetailsTab } from '~/containers/product-detail/product-details/types'
import { space } from '~/gatsby-plugin-theme-ui'

const OverviewTab = ({ product }: ProductDetailsTab) => {
  const overview = product.descriptionHtml

  const textCss = {
    fontSize: 16,
    lineHeight: '24px',
  }

  return (
    <Flex
      py={space.tabContent}
      sx={{
        fontSize: '12px',
        flexDirection: ['column', null, 'row'],
      }}
    >
      <Box>
        <BaseTypo
          sxProps={sxStyles => {
            return {
              ...sxStyles,
              ul: {
                ...textCss,
                margin: '16px 0 16px 0',
                padding: '0 24px 0 24px',
              },
              li: {
                ...textCss,
                margin: '0 0 16px 0',
              },
              p: { ...textCss },
              span: { ...textCss },
              a: {
                color: 'muted',
                fontWeight: 'bold',
                textDecoration: 'underline',
              },
            }
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: overview }} />
        </BaseTypo>
      </Box>
    </Flex>
  )
}

export default OverviewTab
