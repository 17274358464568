import { Text, Box, Grid } from 'theme-ui'
import React, { FC, useMemo } from 'react'
import { Select } from '~/components/select'
import ShippingLabel from '~/components/parameters/shipping-label'
import useProductAvailability from '~/hooks/use-product-availability'
import { space } from '~/gatsby-plugin-theme-ui'
import { useProductDetail } from '~/containers/product-detail-v2/Provider'
import { Tag } from '~/componentsV2/tag'
import { FONTS, Paragraph } from '~/styled/components/typography'
import OrderSwatch, { OrderSwatchProps } from '../product-detail-panel/order-swatch'
import { Close } from '../icons/close'
import { F } from 'ramda'
import useMediaQuery from '~/hooks/use-media-query'

const ValueTypes = {
  DEFAULT_TITLE: 'Default Title',
}

type Props = Record<string, any>

const SelectConfiguration: FC<Props> = ({
  option,
  onChange,
  value,
  options,
  showAddSwatch = true,
  label,
  stockControl,
  gridType,
}) => {
  const {
    product,
    productVariant: variant,
    currentBundle,
    productUtils: { isUpholstery },
    flyOutControl,
  } = useProductDetail()
  // const {} = useMediaQuery()

  const { getColorParameterTitle } = useProductAvailability({
    product,
    variant,
  })

  const orderWatchProps = {} as OrderSwatchProps

  const { optionsToShow, modalOptions } = useMemo(() => {
    const target = 8

    if (!option || !option?.values) {
      return {
        optionsToShow: [],
        modalOptions: [],
      }
    }

    const _option = option
    const optionsToShow =
      _option.values.length > target ? _option.values.slice(0, target - 1) : _option.values
    const modalOptions = _option.values.length > target ? _option.values.slice(target - 1) : []

    return {
      optionsToShow,
      modalOptions,
    }
  }, [options, option])

  if (!option || !option?.values) {
    return null
  }

  if (isUpholstery) {
    orderWatchProps.onCustomCart = () => {
      flyOutControl.setFlyOpen(true)
    }
  }

  if (value === ValueTypes.DEFAULT_TITLE) {
    return null
  }
  const isDisabled = (item: any, onLyDisable = false) => {
    if (stockControl) {
      if (onLyDisable) {
        return stockControl.find(i => i.label === item).disabled
      }
      return (
        stockControl.find(i => i.label === item).disabled ||
        stockControl.find(i => i.label === item).currentlyNotInStock
      )
    }
    return false
  }

  const ItemConfig = ({ item }: any) => {
    if (!item) {
      return null
    }
    if (item.indexOf('http') > -1) {
      return (
        <Tag
          key={item}
          active={value === item}
          style={{
            padding: '3px',
            width: '100px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: '#EDEDED',
            cursor: isDisabled(item, true) ? 'not-allowed' : 'pointer',
          }}
          disabled={isDisabled(item)}
          bgColor="transparent"
          onClick={() => {
            if (isDisabled(item, true)) {
              return
            }
            onChange(option, item)
          }}
        >
          <Box
            sx={{
              fontFamily: FONTS.Helvetica,
              backgroundColor: '#EDEDED',
            }}
          >
            <img src={item} style={{ width: '100%', height: '100%' }} />
          </Box>
        </Tag>
      )
    }
    return (
      <Tag
        key={item}
        active={value === item}
        style={{
          padding: '3px',
          cursor: 'pointer',
        }}
        bgColor="transparent"
        onClick={() => onChange(option, item)}
        disabled={isDisabled(item)}
      >
        <Box
          sx={{
            fontFamily: FONTS.Helvetica,
            padding: '5px 10px',
            backgroundColor: '#EDEDED',
          }}
        >
          {item}
        </Box>
      </Tag>
    )
  }

  const ModalOptions = () => {
    const [showModal, setShowModal] = React.useState(false)

    const active = useMemo(() => {
      const active = modalOptions.find(item => item === value)
      if (active) {
        setShowModal(true)
      }
      return active
    }, [value, modalOptions])

    if (!modalOptions.length) {
      return null
    }

    return (
      <>
        {!showModal ? (
          <Tag
            style={{
              padding: optionsToShow[0].indexOf('http') > -1 ? '3px' : '0',
              cursor: 'pointer',
              width: '100px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
            bgColor="transparent"
            onClick={() => setShowModal(!showModal)}
            active={active}
          >
            <Box
              sx={{
                fontFamily: FONTS.Helvetica,
                padding: '5px 10px',
                backgroundColor: '#EDEDED',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              + More
            </Box>
          </Tag>
        ) : null}
        {showModal ? modalOptions.map((item: any) => <ItemConfig key={item} item={item} />) : null}
        {showModal ? (
          <Tag
            style={{
              padding: optionsToShow[0].indexOf('http') > -1 ? '3px' : '0',
              cursor: 'pointer',
              width: '100px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
            bgColor="transparent"
            onClick={() => setShowModal(!showModal)}
          >
            <Box
              sx={{
                fontFamily: FONTS.Helvetica,
                padding: '5px 10px',
                backgroundColor: '#EDEDED',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              - Less
            </Box>
          </Tag>
        ) : null}
      </>
    )
  }

  if (option?.values?.length === 1) {
    return (
      <Box
        sx={{
          color: 'zambezi',
          height: 64,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="select-parameter"
      >
        <Text
          sx={{
            display: 'inline-flex',
            justifyContent: 'start',
            textTransform: 'uppercase',
          }}
          as="span"
        >
          <span className="label">{option.label} </span>
          <span className="separator">|</span>
          <Text
            as="span"
            sx={{
              color: 'text',
              ml: 'sm',
            }}
          >
            {option.values[0]}
          </Text>
        </Text>
        {options?.length === 1 ? <ShippingLabel>{getColorParameterTitle()}</ShippingLabel> : null}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        color: 'zambezi',
      }}
      className="select-parameter multiple"
    >
      <Box className="group">
        <span className="label">{option.label}</span>
        <span className="separator">|</span>
        <span>{label || value}</span>
      </Box>

      <Box
        sx={
          optionsToShow[0].indexOf('http') > -1
            ? {
                display: 'grid',
                gap: '10px',
                mt: '10px',
                gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
              }
            : {
                display: 'flex',
                gap: '10px',
                mt: '10px',
                flexWrap: 'wrap',
              }
        }
      >
        <>
          {optionsToShow.map((item: any) => (
            <ItemConfig key={item} item={item} />
          ))}
          <ModalOptions />
        </>
      </Box>
    </Box>
  )
}

export default SelectConfiguration
