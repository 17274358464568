import React, {
  createContext,
  FC,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  CheckoutLineItemInput,
  CheckoutProductVariant,
  CollectionType,
  ProductItemComponentType,
  ProductVariantType,
} from '~/@types/models'
import useOnClickOutside from '~/hooks/use-on-click-outside'
import useLockedBody from '~/hooks/use-locked-body'
import { useCartContext } from '~/context/cart-context'
import useQuantityNote from '~/hooks/use-quantity-note'
import { Attribute } from 'shopify-storefront-api-typings'

interface FlyOutContextType {
  refFlyOut: Ref<HTMLDivElement>
  isFlyOut: boolean
  closeFlyOut(): void
  openFlyOut(): void
  selectedProductVariant: ProductItemComponentType | null
  clearSelectedProductVariant(): void
  selectedItems: string[]
  toggleSelectedProductVariant(
    productVariant: ProductItemComponentType,
    product: CollectionType
  ): void
  selectedProduct: CollectionType | null
  getVariantName(productVariant: ProductItemComponentType): string
}

const FlyOutContext = createContext<FlyOutContextType>({
  refFlyOut: null,
  isFlyOut: false,
  closeFlyOut: () => undefined,
  openFlyOut: () => undefined,
  selectedProductVariant: null,
  clearSelectedProductVariant: () => undefined,
  selectedItems: [],
  toggleSelectedProductVariant: () => undefined,
  selectedProduct: null,
  getVariantName: () => '',
})

interface INextSaveItem {
  item: CheckoutLineItemInput
  title: string
  variant: CheckoutProductVariant
  checkoutAttributes?: readonly Attribute[]
}

interface FlyOutProviderProps {
  open?: boolean
  onClose?: () => void
  onOpen?: () => void
}

const FlyOutProvider: FC<FlyOutProviderProps> = ({ children, open, onClose, onOpen }) => {
  const [isFlyOut, setIsFlyOut] = useState(false)
  const [
    selectedProductVariant,
    setSelectedProductVariant,
  ] = useState<ProductItemComponentType | null>(null)

  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const [selectedProduct, setSelectedProduct] = useState<CollectionType | null>(null)
  const refFlyOut = useRef<HTMLDivElement>(null)
  const { items, saveItem } = useCartContext()
  const { getNoteBySelectedQuantity } = useQuantityNote({})

  const clearSelectedProductVariant = () => setSelectedProductVariant(null)

  const [nextSaveItem, setNextSaveItem] = useState<INextSaveItem>()

  const state: FlyOutContextType = {
    refFlyOut,
    isFlyOut,
    closeFlyOut: () => {
      clearSelectedProductVariant()
      setIsFlyOut(false)
      onClose?.()
    },
    openFlyOut: () => {
      setIsFlyOut(true)
      onOpen?.()
    },
    selectedProductVariant,
    clearSelectedProductVariant,
    toggleSelectedProductVariant: useCallback(async (variant, collection) => {
      const cheapestVariant = (variant.cheapestVariant as unknown) as CheckoutProductVariant

      setSelectedItems(old => {
        const currentItem = old.find(shopifyId => shopifyId === cheapestVariant.shopifyId)

        const quantity = currentItem ? -100 : 1

        const message = getNoteBySelectedQuantity(cheapestVariant.quantityAvailable, quantity)

        const product: CheckoutLineItemInput = {
          variantId: cheapestVariant.shopifyId,
          quantity,
          customAttributes: [
            {
              key: `Quantity`,
              value: message || '',
            },
          ],
        }

        setNextSaveItem({
          item: product,
          title: variant.title,
          variant: {
            ...((variant.cheapestVariant as unknown) as CheckoutProductVariant),
            image: {
              transformedSrc: variant.mainImage.srcMobileSmall,
            },
          },
        })

        if (currentItem) {
          return old.filter(shopifyId => shopifyId !== currentItem)
        } else {
          return [...old, cheapestVariant.shopifyId]
        }
      })
    }, []),
    selectedProduct,
    getVariantName: productVariant => productVariant.title.split('|')[1],
    selectedItems,
  }

  useOnClickOutside(refFlyOut, state.closeFlyOut, 'request-custom-quote-dialog')
  useLockedBody(isFlyOut)

  const handleClickEsc = (ev: KeyboardEvent) => {
    if (ev.keyCode === 27) {
      state.closeFlyOut()
    }
  }

  useEffect(() => {
    if (isFlyOut) {
      document.addEventListener('keydown', handleClickEsc)
    } else {
      document.removeEventListener('keydown', handleClickEsc)
    }

    return () => {
      document.removeEventListener('keydown', handleClickEsc)
    }
  }, [isFlyOut])

  useEffect(() => {
    if (open !== undefined) {
      if (open) {
        state.openFlyOut()
      } else {
        state.closeFlyOut()
      }
    }
  }, [open])

  useEffect(() => {
    setSelectedItems(items.map(item => item.variant.id))
  }, [items])

  useEffect(() => {
    if (nextSaveItem) {
      const params = nextSaveItem
      setNextSaveItem(undefined)
      saveItem(params.item, params.title, params.variant)
    }
  }, [nextSaveItem, saveItem])

  return <FlyOutContext.Provider value={state}>{children}</FlyOutContext.Provider>
}

const useFlyOutContext = () => {
  const context = useContext(FlyOutContext)

  if (context === undefined) {
    throw new Error('FlyOutContext must be used within a FlyOutProvider')
  }

  return context
}

export { FlyOutProvider, useFlyOutContext }
