/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { FC, ReactNode, useMemo } from 'react'
import Accordion from '~/components/accordion'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import OverviewTab from '~/containers/product-detail/product-details/overview-tab'
import SpecsTab from '~/containers/product-detail/product-details/specs-tab'
import { assoc, curry, keys, map, reduce } from 'ramda'
import { space } from '~/gatsby-plugin-theme-ui'
import { boolean } from '@storybook/addon-knobs'

type Props = {
  data: Array<{
    label: string
    content: string | ReactNode
    defaultOpen: boolean
  }>
}
const DetailCategory: FC<Props> = ({ data }) => {
  const renameKeys = curry((keysMap, obj) =>
    reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
  )

  const transformedData = map(renameKeys({ question: 'label', content: 'content' }), data)

  const defaultIndexes = data
    .filter(o => o.defaultOpen)
    .map(o => {
      return data.indexOf(o)
    })

  return (
    <Box>
      <Box
        sx={{
          marginTop: 10,
          // mx: [space.md1n, null, 0],
          mb: 25,
        }}
      >
        <Accordion
          enableMarkdownContent
          data={transformedData}
          defaultIndexes={defaultIndexes}
          sxPanel={{
            px: [null, null, space.md],
            py: [null, null, space.md],
            lineHeight: '40px',
          }}
          sxButton={{
            color: 'muted',
            textAlign: ['left', null, 'left'],
            // borderBottom: 'none',
            px: [space.gridGap1, null, space.xxs],
            minHeight: 50,
            py: 10,
          }}
          sxLabel={{
            marginLeft: '0px',
          }}
        />
      </Box>
    </Box>
  )
}

type Tabs = 'DESCRIPTION' | 'PRODUCT SPECS'

const mapProductDetails = (
  data: ProductItemComponentType,
  productVariant: ProductVariantType,
  defaultOpen?: Tabs
) => {
  const overView = {
    label: 'DESCRIPTION',
    defaultOpen: defaultOpen === 'DESCRIPTION',
    content: <OverviewTab product={data} variant={productVariant} />,
  }

  const specs = {
    label: 'PRODUCT SPECS',
    defaultOpen: defaultOpen === 'PRODUCT SPECS',
    content: <SpecsTab product={data} variant={productVariant} />,
  }

  return [specs, overView].filter(tab => {
    return tab.content !== null
  })
}

type PropTypes = {
  product: ProductItemComponentType
  productVariant: ProductVariantType
  defaultOpen?: Tabs
}

const ProductDetailsContainer: FC<PropTypes> = ({ product, productVariant, defaultOpen }) => {
  const data = useMemo(() => {
    return mapProductDetails(product, productVariant, defaultOpen)
  }, [product, productVariant])

  return <DetailCategory data={data} />
}

export default ProductDetailsContainer
