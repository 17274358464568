/** @jsx jsx */
import { jsx, Box, SxStyleProp } from 'theme-ui'
import { FC, useState, Fragment } from 'react'
import Slider, { Settings } from 'react-slick'
import ArrowSecondary from '~/components/arrow-secondary'
import SlickDots from '~/slick-dots'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import ImageWrapper from '~/components/image-wrapper'
import { ProductItemType } from '~/@types/models'
import { ISimpleProductType } from '~/context/products'
import { ProductCardSimple } from '../productCard'
import { NavigationButton } from '~/componentsV2/navigationButton'
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'
type Props = {
  items: ISimpleProductType[]
  GridItem: typeof ProductCardSimple
  gridProps?: Record<string, any>
  sx?: SxStyleProp
  carouselOnMobile?: boolean
  gridItemProps?: object
  selectedOption?: string
  trackLocation?: string
}

export const ProductFieldCarouselV2: FC<Props> = props => {
  const { trackLocation, GridItem, items, gridItemProps = {} } = props

  const theme = useTheme()

  const isDesktop = useMediaQuery({ query: theme.mediaQueries.desktop })

  const handleArrowClick = () => {
    if (typeof window !== 'undefined') {
      window.hj('event', `Product Carousel Scroll - ${trackLocation || ''}`)
    }
  }

  const prevArrow = (
    <NavigationButton action="prev" spacing={-30}>
      <RiArrowLeftSLine />
    </NavigationButton>
  )
  const nextArrow = (
    <NavigationButton action="next" spacing={-30}>
      <RiArrowRightSLine />
    </NavigationButton>
  )
  const [settings] = useState<Settings>({
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow,
    nextArrow,
    arrows: true,
    dots: false,
    centerMode: false,
    appendDots: SlickDots,
    // adaptiveHeight: true,
    afterChange(currentSlide) {
      handleArrowClick()
    },
    infinite: false,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.maxBreakPoints.tablet,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.maxBreakPoints.mobile,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          // adaptiveHeight: true,
          // centerMode: true,
        },
      },
    ],
  })

  // SSR hack - Slider get rendered bad when combining ssr + client side
  if (typeof window === 'undefined') {
    return null
  }

  const imageComponent = items.map(productItem => {
    let image

    return (
      <GridItem
        key={`slide-${Math.random()}`}
        image={image}
        product={productItem}
        {...gridItemProps}
      />
    )
  })
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      <Box
        sx={{
          overflow: ['visible', 'hidden'],
          img: {
            maxHeight: '100%',
          },
          '.slick-slider': {
            touchAction: 'auto !important',
          },
          '.slick-arrow': {
            transition: 'color .3s',
          },
          '.slick-disabled': {
            color: 'rgba(16, 16, 16, 0.3)',
          },
          '.slick-track': {
            float: 'left',
          },
          // '.slick-list': {
          //   padding: ['0 20% 0 0 !important', 'padding: 0px 50px'],
          //   overflow: ['visible', 'hidden'],
          // },
        }}
      >
        <Box
          sx={{
            px: [0, 38],
            order: isDesktop ? 1 : 'initial',
          }}
        >
          <Slider {...settings}>{imageComponent}</Slider>
        </Box>
      </Box>
    </Fragment>
  )
}
