/** @jsx jsx **/
import { jsx, Box, Grid } from 'theme-ui'
import { FC } from 'react'

type Props = {}

const LoadingForm: FC<Props> = () => {
  return (
    <Grid
      sx={{
        overflow: 'hidden',
        direction: 'ltr',
        position: 'relative',
        '@keyframes phAnimation': {
          '0%': { transform: 'translate3d(-30%, 0, 0)' },
          to: { transform: 'translate3d(30%, 0, 0)' },
        },
        ':before': {
          content: '""',
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '50%',
          zIndex: 1,
          width: '500%',
          marginLeft: '-250%',
          WebkitAnimation: 'phAnimation 0.8s linear infinite',
          animation: 'phAnimation 0.8s linear infinite',
          background: `linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0) 46%,
          hsla(0, 0%, 100%, 0.35) 50%,
          hsla(0, 0%, 100%, 0) 54%)
          50% 50%`,
        },
      }}
    >
      <Box mb={30}>
        <FakeInput />
      </Box>
      <Box mb={30}>
        <FakeInput />
      </Box>
      <Grid columns={[1, null, null, 2]} gap={32}>
        <Box mb={30}>
          <FakeInput />
        </Box>
        <Box mb={30}>
          <FakeInput />
        </Box>
      </Grid>
      <Box mb={30}>
        <FakeInput />
      </Box>
      <Box
        mt={50}
        sx={{
          width: '50%',
          ml: 'auto',
        }}
      >
        <FakeInput />
      </Box>
    </Grid>
  )
}

const FakeInput = () => {
  return (
    <Box
      sx={{
        height: 64,
        bg: 'productBackground',
      }}
    />
  )
}

export default LoadingForm
