import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react'

interface OffsetScrollProps {
  offsetY?: number
  offsetTarget: MutableRefObject<HTMLDivElement | null>
  style?: React.CSSProperties
  children: React.ReactNode
}

export const AffixOffsetScroll: React.FC<OffsetScrollProps> = ({
  offsetY = 0,
  offsetTarget,
  style,
  children,
}) => {
  const original = useRef<HTMLDivElement | null>(null)
  const target = useRef<HTMLDivElement | null>(null)

  const calculate = useCallback(() => {
    if (target.current && original.current && offsetTarget.current) {
      offsetTarget.current.parentElement!.style.position = 'static'
      const rectOffset = offsetTarget.current.getBoundingClientRect()
      const rectOriginal = original.current.getBoundingClientRect()
      const _offsetY = Math.min(
        rectOriginal.height,
        (rectOriginal.top - rectOriginal.height - rectOffset.top - offsetY - window.scrollY) * -1
      )
      offsetTarget.current.parentElement!.style.position = ''

      target.current.style.transform = `translateY(${_offsetY}px)`
    }
  }, [offsetY, offsetTarget?.current])

  useEffect(() => {
    const handleScroll = () => calculate()

    calculate()

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('click', handleScroll)

    const scrollToOriginal = () => {
      const rect = original.current!.getBoundingClientRect()
      window.scrollTo({
        top: rect.top + window.scrollY - rect.height,
        behavior: 'smooth',
      })
    }
    target.current?.addEventListener('click', scrollToOriginal)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      target.current?.removeEventListener('click', scrollToOriginal)
    }
  }, [calculate])

  return (
    <>
      <div
        ref={target}
        style={{
          display: 'inline-block',
          ...style,
        }}
      >
        {children}
      </div>
      <div
        ref={original}
        style={{
          height: target.current?.offsetHeight,
        }}
      />
    </>
  )
}
