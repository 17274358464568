/** @jsx jsx **/
import { jsx, BaseStyles, SxStyleProp } from 'theme-ui'
import { FC, useMemo } from 'react'

type Props = {
  sxProps?: (currentStyles: SxStyleProp) => SxStyleProp
}

const BaseTypo: FC<Props> = ({ children, sxProps }) => {
  const currentStyles: SxStyleProp = {
    h4: {
      mt: [30, null, 80],
      mb: [20, null, 40],
    },
    ul: {
      variant: 'variants.list.primary',
      my: [40, 40, 40, 40],
    },
    table: {
      variant: 'variants.table.primary',
    },
    a: {
      color: 'muted',
    },
    p: {
      variant: 'text.regular',
    },
  }

  const sxPropsComputed = useMemo(() => {
    if (typeof sxProps === 'function') {
      return sxProps(currentStyles)
    }

    return currentStyles
  }, [sxProps])

  return <BaseStyles sx={sxPropsComputed}>{children}</BaseStyles>
}

export default BaseTypo
