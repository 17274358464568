/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import { FC } from 'react'
import ProductItemToSell from '~/components/product-item-to-sell'
import Section from '~/components/section'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'

type Props = {
  variant: ProductVariantType
  products: readonly ProductItemComponentType[]
}

const YouMightLikeSection: FC<Props> = ({ products, variant }) => (
  // <Section label="You Might Like">
  <Section label="YOU MIGHT LIKE">
    <Grid>
      {products.map(product => (
        <Box
          key={product.id}
          sx={{
            '&:not(:last-child)': {
              mb: [76, null, 80],
            },
          }}
        >
          <ProductItemToSell product={product} variant={variant} trackLocation="You might like" />
        </Box>
      ))}
    </Grid>
  </Section>
)

export default YouMightLikeSection
