/** @jsx jsx **/
import { jsx, Box, Image } from 'theme-ui'
import React, { ReactNode } from 'react'
import { ProductItemComponentType } from '~/@types/models'
import { useFlyOutContext } from '~/containers/fly-out/contex'
import theme, { space } from '~/gatsby-plugin-theme-ui'
import ImageWrapper from '~/components/image-wrapper'
import { RiAddLine, RiCheckLine, RiSubtractLine } from '@remixicon/react'
import { SmallText } from '~/styled/components/typography'

type Props = {
  active: boolean
  variant: ProductItemComponentType
}

const iconProps = {
  size: 50,
  color: 'white',
}

const FlyOutGroupItemInner: React.FC<Props> = ({ active, variant }) => {
  const { getVariantName } = useFlyOutContext()
  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span className="check-icon">{active && <RiCheckLine {...iconProps} />}</span>
          <span className="hover-icon">
            {!active ? <RiAddLine {...iconProps} /> : <RiSubtractLine {...iconProps} />}
          </span>
        </Box>

        <ImageWrapper ratio={1}>
          <Image
            src={variant.mainImage.srcMobileSmall}
            srcSet={`
              ${variant.mainImage.srcSmall} 544w,
              ${variant.mainImage.srcMobileSmall} 1450w
              `}
            alt={getVariantName(variant) || 'Product Image'}
            loading="lazy"
          />
        </ImageWrapper>
      </Box>
      {/* <Box
        mt={space.xs}
        sx={{
          fontSize: 1,
        }}
      >
        {getVariantName(variant)}
      </Box> */}
      <SmallText
        style={{
          marginTop: space.xs,
          textTransform: 'uppercase',
        }}
      >
        {getVariantName(variant)}
      </SmallText>
    </React.Fragment>
  )
}

export default React.memo(FlyOutGroupItemInner)
