/** @jsx jsx **/
// @ts-ignore
import { FC, useState, useCallback, Fragment } from 'react'
import { Box, Grid, jsx, Text } from 'theme-ui'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import { hubspotFormIds } from '~/common/hubspot'
import { AddToCartButton } from '~/components/add-to-card-button'
import { Button } from '~/components/button'
import CustomQuickShipFormDialog from '~/components/custom-quick-ship-form-dialog'
import { Input } from '~/components/input'
import renderParameter from '~/components/parameters/render-parameter'
import { HubspotForm, OnFormReadyType } from '~/utils/hubspotForm'

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantType
  onChange: (option) => void
  hiddenColors?: boolean
}

const reduceOptions = (options, valueIsNull = false) =>
  options.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: valueIsNull ? null : curr.value,
    }
  }, {})

const removeInchesMark = (value: string) => String(value).replaceAll('"', '')

export const isCreamerArt = (tags: string[]) => tags.includes('craemer-artwork')

export const isPrintArt = (tags: string[]) => tags.includes('print-art')

const CustomQuickShipArt: FC<Props> = ({ product, onChange, variant, hiddenColors = false }) => {
  let initialValues = reduceOptions(product.options, true)
  if (variant) {
    initialValues = reduceOptions(variant.selectedOptions)
  }

  const [values, setValues] = useState(initialValues)
  const CUSTOM_SIZE_OPTION = 'Custom'
  const CUSTOM_SIZE_OPTION_CRAEMER = 'Made to order'
  // todo - refactoring local state - not necessary (???)
  const [sizeValue, setSizeValue] = useState('')
  const [sizeValueCustom, setSizeValueCustom] = useState('')

  const onChangeParameter = useCallback(
    (option, newValue) => {
      if (option.name === 'Size') {
        newValue = removeInchesMark(newValue)
        setSizeValue(newValue)
      }
      const newValues = {
        ...values,
        [option.name]: newValue,
      }
      onChange(newValues)
      setValues(newValues)
      setSizeValueCustom(newValue.Size)
    },
    [values]
  )

  const [showFormDialog, setShowFormDialog] = useState<boolean>(false)
  const openFormDialog = () => setShowFormDialog(true)
  const closeFormDialog = () => setShowFormDialog(false)

  const isCustomValue =
    values.Size === CUSTOM_SIZE_OPTION || sizeValue === CUSTOM_SIZE_OPTION_CRAEMER

  const finalSize = () => {
    if (isCustomValue && sizeValueCustom) {
      return sizeValueCustom
    }
    return sizeValue
  }

  const hubspotFormId = (isCreamerArt(product.tags)
    ? hubspotFormIds.customQuickShipCraemerArtwork
    : hubspotFormIds.customQuickShipArt) as string

  const onFormReady = useCallback<OnFormReadyType>((_, formWrapperRef) => {
    if (!formWrapperRef.current) {
      return
    }

    const productNameInput = formWrapperRef.current.querySelector<HTMLInputElement>(
      '[name="custom_photography_art_product_name"]'
    )

    const productColorInput = formWrapperRef.current.querySelector<HTMLInputElement>(
      '[name="custom_photography_art_product_color"]'
    )

    const productSizeInput = formWrapperRef.current.querySelector<HTMLInputElement>(
      '[name="custom_photography_art_dimensions__in_inches_"]'
    )

    if (productNameInput && productColorInput && productSizeInput) {
      productNameInput.value = product.title
      productColorInput.value = values.Color //Either create color input on form or change this value as user selects color
      productSizeInput.value = finalSize()
    }
  }, [])

  const options = product.options.map(option => {
    const name = option.name

    const availableValues = product.variants.map(variant => {
      const selectedOption = variant.selectedOptions.find(option => option.name === name)

      return selectedOption && selectedOption.value
    })
    if (name === 'Size') {
      availableValues.push(
        isCreamerArt(product.tags) ? CUSTOM_SIZE_OPTION_CRAEMER : CUSTOM_SIZE_OPTION
      )
    }
    const values = new Set(availableValues)
    const finalOptions = {
      ...option,
      values: Array.from(values),
    }

    return finalOptions
  })

  return (
    <Box>
      {options.map(option => {
        const parameter = renderParameter(
          product,
          option,
          values[option.name],
          onChangeParameter,
          variant,
          options
        )

        return (
          <Box key={`option-${option.name}`} className="parameter">
            <Fragment>{parameter}</Fragment>
          </Box>
        )
      })}
      <Grid gap="gridGap3">
        {!isCustomValue && (
          <Text
            sx={{
              fontSize: [3],
              color: 'text',
            }}
          >
            Ready to ship in 3 - 10 days
          </Text>
        )}
        <Box
          sx={{
            display: isCustomValue ? 'block' : 'none',
          }}
        >
          <Input
            setValue
            label={isCreamerArt(product.tags) ? 'Desired size' : 'Custom size'}
            // @ts-ignore
            onChange={e => {
              setSizeValueCustom(e.currentTarget.value)
            }}
          />
          <Text
            mt="gridGap1"
            sx={{
              fontSize: [3],
              color: 'text',
            }}
          >
            Ready to ship in approximately 4 weeks
          </Text>
        </Box>
        {variant && sizeValue !== CUSTOM_SIZE_OPTION ? (
          <AddToCartButton
            variant={variant}
            title={product.title}
            productType={product.productType}
          />
        ) : (
          <Button
            label="Get Quote"
            onClick={() => {
              openFormDialog()
            }}
            disabled={!sizeValue}
          />
        )}
        <CustomQuickShipFormDialog
          showDialog={showFormDialog}
          close={closeFormDialog}
          product={product}
        >
          <HubspotForm formId={hubspotFormId} onReady={onFormReady} />
        </CustomQuickShipFormDialog>
      </Grid>
    </Box>
  )
}

export default CustomQuickShipArt
