/** @jsx jsx */
import { jsx, Heading, Box, SxStyleProp } from 'theme-ui'
import { FC, Fragment, MutableRefObject, ReactNode } from 'react'
import { AffixOffsetScroll } from '~/componentsV2/affixOffsetScroll'

type Props = {
  label?: string | ReactNode
  className?: string
  sxSection?: SxStyleProp
  sxLabel?: SxStyleProp
  affixTileScroll?: MutableRefObject<HTMLDivElement | null>
}

const Section: FC<Props> = ({
  label,
  affixTileScroll,
  children,
  className,
  sxSection,
  sxLabel,
}) => {
  const title = label && (
    <Heading
      as="h2"
      sx={{
        fontSize: [6, null, 7],
        mb: [39, null, 107],
        textAlign: 'left',
        color: 'text',
        ...sxLabel,
      }}
    >
      {label}
    </Heading>
  )

  return (
    <Box
      as="section"
      sx={{
        pt: [85, null, 140],
        ...sxSection,
      }}
      className={className}
    >
      {affixTileScroll ? (
        <AffixOffsetScroll offsetY={40} offsetTarget={affixTileScroll}>
          {title}
        </AffixOffsetScroll>
      ) : (
        title
      )}
      {children}
    </Box>
  )
}

export default Section
