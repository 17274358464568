import { ProductItemComponentType } from '~/@types/models'

const getTearsheetUrl = (product: ProductItemComponentType, selectedColor?: string) => {
  const TEARSHEET_URI = `/tearsheet/${product.handle}`

  return selectedColor ? `${TEARSHEET_URI}?colorOption=${selectedColor}` : TEARSHEET_URI
}

type UseFilesProps = {
  product: ProductItemComponentType
  selectedColor?: string
}

const useFiles = ({ product, selectedColor }: UseFilesProps) => {
  const tearsheetLink = getTearsheetUrl(product, selectedColor)
  const installationGuideLink = product.metafields.installationGuideLink

  const files = [
    // {
    //   href: tearsheetLink,
    //   title: 'TEARSHEET',
    // },
    {
      href: installationGuideLink,
      title: 'Installation Guide',
    },
  ].filter(link => link.href !== null)

  return {
    files,
  }
}

export default useFiles
