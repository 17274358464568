import React, { Fragment, useState } from 'react'
import { space, theme } from '~/gatsby-plugin-theme-ui'
import { Box, Text, Button as ThemeButton } from 'theme-ui'
import { ProductItemComponentQuery, ProductVariantType } from '~/@types/models'

import { Button } from '~/components/button'
import Dialog from '~/components/dialog'
import { GoogleSheetForm } from '~/utils/googlesheetForm'
import { DynamicGoogleSheetForm } from '../googleForm'
import { Tag } from '../tag'
import { FONTS } from '~/styled/components/typography'

interface Props {
  product: ProductItemComponentQuery
  productVariant: ProductVariantType
  showText?: boolean
}

export const RugsCustomSize = ({ product, productVariant, showText = true }: Props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const handleClick = () => {
    setShowDialog(!showDialog)
  }

  const productName = `${product.title}`

  return (
    <Fragment>
      <Box>
        <Box>
          <Tag
            style={{
              padding: '3px',
              cursor: 'pointer',
            }}
            bgColor="transparent"
            onClick={handleClick}
          >
            <Box
              sx={{
                fontFamily: FONTS.Helvetica,
                padding: '5px 10px',
                backgroundColor: '#EDEDED',
              }}
            >
              Custom
            </Box>
          </Tag>
          <Dialog
            showDialog={showDialog}
            close={handleClick}
            title="REQUEST CUSTOM SIZE"
            titlePosition="center"
          >
            <Box p={theme.space.gridGap3}>
              <DynamicGoogleSheetForm
                formName="CustomRugSize"
                description="Thank you for your interest in our Custom Rug Studio. Please provide your specs below and a Custom Concierge will get back to you shortly."
                fields={[
                  {
                    name: 'product_url',
                    hidden: true,
                    value: window.location.href,
                  },
                  {
                    name: 'product_name',
                    label: 'Product Name',
                    col: 6,
                    disabled: true,
                    value: productName,
                  },
                  {
                    name: 'color',
                    label: 'Color',
                    col: 6,
                    disabled: true,
                    value: productVariant.selectedOptions.find(option => option.name === 'Color')
                      ?.value,
                  },
                  {
                    label: 'Name',
                    name: 'name',
                    col: 6,
                    required: true,
                  },
                  {
                    label: 'Phone',
                    name: 'phone_number',
                    col: 6,
                    required: true,
                  },
                  {
                    label: 'Email',
                    name: 'email',
                    validation: ['email'],
                    required: true,
                  },
                  {
                    label: 'Width',
                    name: 'width',
                    col: 6,
                    required: true,
                    type: 'group',
                    join: '´',
                    children: [
                      {
                        name: 'feet',
                        label: 'feet',
                        required: true,
                        type: 'number',
                        value: '0',
                        props: {
                          min: 1,
                        },
                      },
                      {
                        name: 'inches',
                        label: 'inches',
                        type: 'number',
                        after: '"',
                        value: '0',
                        props: {
                          min: 0,
                          max: 11,
                        },
                      },
                    ],
                  },
                  {
                    label: 'Length',
                    name: 'length',
                    col: 6,
                    required: true,
                    type: 'number',
                    join: '´',
                    children: [
                      {
                        name: 'feet',
                        label: 'feet',
                        required: true,
                        type: 'number',
                        value: '0',
                        props: {
                          min: 1,
                        },
                      },
                      {
                        name: 'inches',
                        label: 'inches',
                        type: 'number',
                        value: '0',
                        after: '"',
                        props: {
                          min: 0,
                          max: 11,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Box>
          </Dialog>
        </Box>
      </Box>
    </Fragment>
  )
}
