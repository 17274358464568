/** @jsx jsx **/
import { jsx, Flex, Box, Grid, Heading, Text, Image, Button } from 'theme-ui'
import { FC, useState, useCallback, Fragment } from 'react'
import { Link, navigate } from 'gatsby'
import { AddToCartButton } from '~/components/add-to-card-button'
import ImageWrapper from '~/components/image-wrapper'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import urls from '~/common/urls'
import { formatMoney, toDashLowerCase } from '~/utils/format'
import useFindVariant from '~/hooks/use-find-variant'
import { isNoScale } from '~/utils/product'

type Props = {
  product: ProductItemComponentType
  trackLocation?: string
  variant?: ProductVariantType
}

const ProductItemToSell: FC<Props> = ({ variant, product, trackLocation }) => {
  const [selectedVariant, setSelectedVariant] = useState(() => variant || product.variants[0])
  const [isNotSelectedVariant, setIsNotSelectedVariant] = useState(false)

  const { findVariant } = useFindVariant({
    product,
  })

  const onChangeParameter = useCallback(
    data => {
      const selectedVariant = findVariant(data)

      if (selectedVariant) {
        setSelectedVariant(selectedVariant)
        setIsNotSelectedVariant(false)
      } else {
        setIsNotSelectedVariant(true)
      }
    },
    [product.variants]
  )

  const { compareAtPrice, priceLabel } = useProductVariantInfo({ product, isStartingAt: true })
  const productLink = `${urls.pages.productDetail}/${product.handle}`
  const productImageSize = 424
  const productImageSizes = ['100%', null, productImageSize]
  const element = product.variants.find(el => el.titleSize === variant?.titleSize)
  const isBedding = product.tags.includes('bedding')
  const title = product.variants[0].colorName
    ? product.title + '-' + product.variants[0].colorName.split('-')[0]
    : product.title

  return (
    <Grid
      sx={{
        gridTemplateColumns: ['1fr', null, null, `${productImageSize}px auto 272px`],
        alignItems: 'center',
        gridGap: [45, null, null, 64],
      }}
    >
      {product.mainImage ? (
        <Button
          onClick={() => {
            if (typeof window !== 'undefined' && trackLocation) {
              window.hj('event', `Product Carousel Click - ${trackLocation || ''}`)
            }
            navigate(productLink)
          }}
          sx={{
            position: 'relative',
            all: 'unset',
            cursor: 'pointer',
            img: {
              ...(!isNoScale(product) && {
                '&[class*="bedding"]': {
                  transform: 'scale(1.7)',
                },
                '&[class*="mattress"]': {
                  transform: 'scale(1.7)',
                },
                '&[class*="pillow"]': {
                  transform: 'scale(1.2)',
                },
              }),
            },
          }}
        >
          <ImageWrapper ratio={1} center>
            <Image
              className={toDashLowerCase(product.handle)}
              src={product.mainImage.srcMobile}
              loading="lazy"
              variant="productOverhead"
              alt={product.title || 'Product Image'}
            />
          </ImageWrapper>
        </Button>
      ) : (
        <Box
          sx={{
            overflow: 'hidden',
            minWidth: productImageSizes,
            maxWidth: productImageSizes,
            minHeight: [327, null, productImageSize],
            maxHeight: productImageSizes,
            bg: '#F8F8F8',
          }}
        />
      )}
      <Box>
        <Heading
          sx={{
            mb: 'sm1',
          }}
        >
          {title}
        </Heading>
        {/* {compareAtPrice && <CompareAtPrice price={compareAtPrice} />} */}
        <Flex
          sx={{
            flexWrap: 'wrap',
          }}
        >
          <Text
            variant="large"
            sx={{
              mr: 24,
            }}
          >
            {!isBedding &&
              (element ? (
                <Fragment>
                  <Text variant="large">{formatMoney(element.price.amount)}</Text>
                  <Text
                    variant="small"
                    sx={{
                      mr: 24,
                    }}
                  >
                    {element.titleSize}
                  </Text>
                </Fragment>
              ) : (
                <Text
                  variant="micro"
                  // sx={{
                  //   my: 'gridGap1',
                  //   position: 'absolute',
                  //   bottom: '100%',
                  // }}
                >
                  This size is not available in this color. Please check other sizes.
                </Text>
              ))}
          </Text>
          {product.availableForSale && (
            <Text
              variant="regular"
              sx={{
                color: 'muted',
              }}
            >
              In Stock
            </Text>
          )}
        </Flex>
      </Box>
      <Grid
        gap={26}
        sx={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        {element && !isBedding ? (
          <Fragment>
            <AddToCartButton
              variant={element}
              title={product.title}
              productType={product.productType}
              label="Add to Cart"
            />
            <Button
              as="link"
              to={productLink}
              variant="secondary"
              label="View Product"
              hasArrow={false}
            />
          </Fragment>
        ) : (
          <Button
            as="link"
            to={productLink}
            variant="secondary"
            label="View Full Product"
            hasArrow={false}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ProductItemToSell
